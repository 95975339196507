/*! For license information please see imou-player.js.LICENSE.txt */
!(function (t, e) {
  "object" == typeof exports && "object" == typeof module ? (module.exports = e()) : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? (exports.library = e()) : (t.library = e());
})(self, () =>
  (() => {
    var t = {
        132: (t, e, n) => {
          var i;

          function a(t) {
            return (
              (a =
                "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
                  ? function (t) {
                      return typeof t;
                    }
                  : function (t) {
                      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
                    }),
              a(t)
            );
          }

          !(function (t) {
            var e,
              n = t.document,
              i = { modules: {}, status: {}, timeout: 10, event: {} },
              r = function () {
                this.v = "2.6.7";
              },
              o = t.LAYUI_GLOBAL || {},
              s =
                ((e = n.currentScript
                  ? n.currentScript.src
                  : (function () {
                      for (var t, e = n.scripts, i = e.length - 1, a = i; a > 0; a--)
                        if ("interactive" === e[a].readyState) {
                          t = e[a].src;
                          break;
                        }
                      return t || e[i].src;
                    })()),
                (i.dir = o.dir || e.substring(0, e.lastIndexOf("/") + 1))),
              c = function (e, n) {
                (n = n || "log"), t.console && console[n] && console[n]("layui error hint: ".concat(e));
              },
              l = "undefined" != typeof opera && "[object Opera]" === opera.toString(),
              u = (i.builtin = {
                lay: "lay",
                layer: "layer",
                laydate: "laydate",
                laypage: "laypage",
                laytpl: "laytpl",
                layedit: "layedit",
                form: "form",
                upload: "upload",
                dropdown: "dropdown",
                transfer: "transfer",
                tree: "tree",
                table: "table",
                element: "element",
                rate: "rate",
                colorpicker: "colorpicker",
                slider: "slider",
                carousel: "carousel",
                flow: "flow",
                util: "util",
                code: "code",
                jquery: "jquery",
                all: "all",
                "layui.all": "layui.all",
              });
            (r.prototype.cache = i),
              (r.prototype.define = function (t, e) {
                return (
                  "function" == typeof t && ((e = t), (t = [])),
                  this.use(
                    t,
                    function () {
                      var t = function (t, e) {
                        (m[t] = e), (i.status[t] = !0);
                      };
                      return (
                        "function" == typeof e &&
                          e(function (n, a) {
                            t(n, a),
                              (i.callback[n] = function () {
                                e(t);
                              });
                          }),
                        this
                      );
                    },
                    null,
                    "define"
                  ),
                  this
                );
              }),
              (r.prototype.use = function (e, a, r, o) {
                var d = this,
                  h = (i.dir = i.dir ? i.dir : s),
                  y = n.getElementsByTagName("head")[0];
                (e = "string" == typeof e ? [e] : "function" == typeof e ? ((a = e), ["all"]) : e),
                  t.jQuery &&
                    jQuery.fn.on &&
                    (d.each(e, function (t, n) {
                      "jquery" === n && e.splice(t, 1);
                    }),
                    (m.jquery = m.$ = jQuery));
                var f = e[0],
                  p = 0;

                function v(t, e) {
                  var n = "PLaySTATION 3" === navigator.platform ? /^complete$/ : /^(complete|loaded)$/;
                  ("load" === t.type || n.test((t.currentTarget || t.srcElement).readyState)) &&
                    ((i.modules[f] = e),
                    y.removeChild(_),
                    (function t() {
                      if (++p > (1e3 * i.timeout) / 4) return c("".concat(f, " is not a valid module"), "error");
                      i.status[f] ? g() : setTimeout(t, 4);
                    })());
                }

                function g() {
                  r.push(m[f]),
                    e.length > 1
                      ? d.use(e.slice(1), a, r, o)
                      : "function" == typeof a &&
                        (function () {
                          if (m.jquery && "function" == typeof m.jquery && "define" !== o)
                            return m.jquery(function () {
                              a.apply(m, r);
                            });
                          a.apply(m, r);
                        })();
                }

                if (((r = r || []), (i.host = i.host || (h.match(/\/\/([\s\S]+?)\//) || ["//".concat(location.host, "/")])[0]), 0 === e.length || (m["layui.all"] && u[f]))) return g(), d;
                var b,
                  w = "".concat((u[f] ? "".concat(h, "modules/") : /^\{\/\}/.test(d.modules[f]) ? "" : i.base || "") + (d.modules[f] || f), ".js");
                if (((w = w.replace(/^\{\/\}/, "")), !i.modules[f] && m[f] && (i.modules[f] = w), i.modules[f]))
                  !(function t() {
                    if (++p > (1e3 * i.timeout) / 4) return c("".concat(f, " is not a valid module"), "error");
                    "string" == typeof i.modules[f] && i.status[f] ? g() : setTimeout(t, 4);
                  })();
                else {
                  var _ = n.createElement("script");
                  (_.async = !0),
                    (_.charset = "utf-8"),
                    (_.src = w + ((b = !0 === i.version ? i.v || new Date().getTime() : i.version || "") ? "?v=".concat(b) : "")),
                    y.appendChild(_),
                    !_.attachEvent || (_.attachEvent.toString && _.attachEvent.toString().indexOf("[native code") < 0) || l
                      ? _.addEventListener(
                          "load",
                          function (t) {
                            v(t, w);
                          },
                          !1
                        )
                      : _.attachEvent("onreadystatechange", function (t) {
                          v(t, w);
                        }),
                    (i.modules[f] = w);
                }
                return d;
              }),
              (r.prototype.getStyle = function (e, n) {
                var i = e.currentStyle ? e.currentStyle : t.getComputedStyle(e, null);
                return i[i.getPropertyValue ? "getPropertyValue" : "getAttribute"](n);
              }),
              (r.prototype.link = function (t, e, a) {
                var r = this,
                  o = n.getElementsByTagName("head")[0],
                  s = n.createElement("link");
                "string" == typeof e && (a = e);
                var l = (a || t).replace(/\.|\//g, ""),
                  u = (s.id = "layuicss-".concat(l)),
                  d = "creating",
                  h = 0;
                return (
                  (s.rel = "stylesheet"),
                  (s.href = t + (i.debug ? "?v=".concat(new Date().getTime()) : "")),
                  (s.media = "all"),
                  n.getElementById(u) || o.appendChild(s),
                  "function" != typeof e ||
                    (function a(o) {
                      var s = n.getElementById(u);
                      if (++h > (1e3 * i.timeout) / 100) return c("".concat(t, " timeout"));
                      1989 === parseInt(r.getStyle(s, "width"))
                        ? (o === d && s.removeAttribute("lay-status"), s.getAttribute("lay-status") === d ? setTimeout(a, 100) : e())
                        : (s.setAttribute("lay-status", d),
                          setTimeout(function () {
                            a(d);
                          }, 100));
                    })(),
                  r
                );
              }),
              (r.prototype.addcss = function (t, e, n) {
                return function () {};
              }),
              (i.callback = {}),
              (r.prototype.factory = function (t) {
                if (m[t]) return "function" == typeof i.callback[t] ? i.callback[t] : null;
              }),
              (r.prototype.img = function (t, e, n) {
                var i = new Image();
                if (((i.src = t), i.complete)) return e(i);
                (i.onload = function () {
                  (i.onload = null), "function" == typeof e && e(i);
                }),
                  (i.onerror = function (t) {
                    (i.onerror = null), "function" == typeof n && n(t);
                  });
              }),
              (r.prototype.config = function (t) {
                for (var e in (t = t || {})) i[e] = t[e];
                return this;
              }),
              (r.prototype.modules = (function () {
                var t = {};
                for (var e in u) t[e] = u[e];
                return t;
              })()),
              (r.prototype.extend = function (t) {
                var e = this;
                for (var n in (t = t || {})) e[n] || e.modules[n] ? c("".concat(n, " Module already exists"), "error") : (e.modules[n] = t[n]);
                return e;
              }),
              (r.prototype.router = function (t) {
                var e = { path: [], search: {}, hash: ((t = t || location.hash).match(/[^#](#.*$)/) || [])[1] || "" };
                return /^#\//.test(t)
                  ? ((t = t.replace(/^#\//, "")),
                    (e.href = "/".concat(t)),
                    (t = t.replace(/([^#])(#.*$)/, "$1").split("/") || []),
                    this.each(t, function (t, n) {
                      /^\w+=/.test(n) ? ((n = n.split("=")), (e.search[n[0]] = n[1])) : e.path.push(n);
                    }),
                    e)
                  : e;
              }),
              (r.prototype.url = function (t) {
                var e, n;
                return {
                  pathname: (t ? ((t.match(/\.[^.]+?\/.+/) || [])[0] || "").replace(/^[^\/]+/, "").replace(/\?.+/, "") : location.pathname).replace(/^\//, "").split("/"),
                  search:
                    ((e = {}),
                    (n = (t ? ((t.match(/\?.+/) || [])[0] || "").replace(/\#.+/, "") : location.search).replace(/^\?+/, "").split("&")),
                    this.each(n, function (t, n) {
                      var i = n.indexOf("="),
                        a = i < 0 ? n.substr(0, n.length) : 0 !== i && n.substr(0, i);
                      a && (e[a] = i > 0 ? n.substr(i + 1) : null);
                    }),
                    e),
                  hash: this.router(t ? (t.match(/#.+/) || [])[0] || "/" : location.hash),
                };
              }),
              (r.prototype.data = function (e, n, i) {
                if (((e = e || "layui"), (i = i || localStorage), t.JSON && t.JSON.parse)) {
                  if (null === n) return delete i[e];
                  n = "object" === a(n) ? n : { key: n };
                  try {
                    var r = JSON.parse(i[e]);
                  } catch (t) {
                    r = {};
                  }
                  return "value" in n && (r[n.key] = n.value), n.remove && delete r[n.key], (i[e] = JSON.stringify(r)), n.key ? r[n.key] : r;
                }
              }),
              (r.prototype.sessionData = function (t, e) {
                return this.data(t, e, sessionStorage);
              }),
              (r.prototype.device = function (e) {
                var n = navigator.userAgent.toLowerCase(),
                  i = function (t) {
                    var e = new RegExp("".concat(t, "/([^\\s\\_\\-]+)"));
                    return (t = (n.match(e) || [])[1]) || !1;
                  },
                  a = {
                    os: /windows/.test(n) ? "windows" : /linux/.test(n) ? "linux" : /iphone|ipod|ipad|ios/.test(n) ? "ios" : /mac/.test(n) ? "mac" : void 0,
                    ie: !(!t.ActiveXObject && !("ActiveXObject" in t)) && ((n.match(/msie\s(\d+)/) || [])[1] || "11"),
                    weixin: i("micromessenger"),
                  };
                return e && !a[e] && (a[e] = i(e)), (a.android = /android/.test(n)), (a.ios = "ios" === a.os), (a.mobile = !(!a.android && !a.ios)), a;
              }),
              (r.prototype.hint = function () {
                return { error: c };
              }),
              (r.prototype._typeof = function (t) {
                return null === t ? String(t) : "object" === a(t) || "function" == typeof t ? ((e = (e = Object.prototype.toString.call(t).match(/\s(.+)\]$/) || [])[1] || "Object"), new RegExp("\\b(".concat("Function|Array|Date|RegExp|Object|Error|Symbol", ")\\b")).test(e) ? e.toLowerCase() : "object") : a(t);
                var e;
              }),
              (r.prototype._isArray = function (e) {
                var n,
                  i = this._typeof(e);
                return !(!e || "object" !== a(e) || e === t) && ((n = "length" in e && e.length), "array" === i || 0 === n || ("number" == typeof n && n > 0 && n - 1 in e));
              }),
              (r.prototype.each = function (t, e) {
                var n,
                  i = this,
                  a = function (t, n) {
                    return e.call(n[t], t, n[t]);
                  };
                if ("function" != typeof e) return i;
                if (((t = t || []), i._isArray(t))) for (n = 0; n < t.length && !a(n, t); n++);
                else for (n in t) if (a(n, t)) break;
                return i;
              }),
              (r.prototype.sort = function (t, e, n) {
                var i = JSON.parse(JSON.stringify(t || []));
                return e
                  ? (i.sort(function (t, n) {
                      var i = /^-?\d+$/,
                        a = t[e],
                        r = n[e];
                      return i.test(a) && (a = parseFloat(a)), i.test(r) && (r = parseFloat(r)), a - r;
                    }),
                    n && i.reverse(),
                    i)
                  : i;
              }),
              (r.prototype.stope = function (e) {
                e = e || t.event;
                try {
                  e.stopPropagation();
                } catch (t) {
                  e.cancelBubble = !0;
                }
              });
            var d = "LAYUI-EVENT-REMOVE";
            (r.prototype.onevent = function (t, e, n) {
              return "string" != typeof t || "function" != typeof n ? this : r.event(t, e, null, n);
            }),
              (r.prototype.event = r.event =
                function (t, e, n, a) {
                  var r = this,
                    o = null,
                    s = (e || "").match(/\((.*)\)$/) || [],
                    c = "".concat(t, ".").concat(e).replace(s[0], ""),
                    l = s[1] || "",
                    u = function (t, e) {
                      !1 === (e && e.call(r, n)) && null === o && (o = !1);
                    };
                  return n === d
                    ? (delete (r.cache.event[c] || {})[l], r)
                    : a
                    ? ((i.event[c] = i.event[c] || {}), (i.event[c][l] = [a]), this)
                    : (m.each(i.event[c], function (t, e) {
                        "{*}" !== l ? ("" === t && m.each(e, u), l && t === l && m.each(e, u)) : m.each(e, u);
                      }),
                      o);
                }),
              (r.prototype.on = function (t, e, n) {
                return this.onevent.call(this, e, t, n);
              }),
              (r.prototype.off = function (t, e) {
                return this.event.call(this, e, t, d);
              });
            var h,
              m = new r(),
              y = window.document,
              f = function (t) {
                return new p(t);
              },
              p = function (t) {
                for (var e = 0, n = "object" === a(t) ? [t] : ((this.selector = t), y.querySelectorAll(t || null)); e < n.length; e++) this.push(n[e]);
              };
            (p.prototype = []),
              (p.prototype.constructor = p),
              (f.extend = function () {
                var t = 1,
                  e = arguments,
                  n = function t(e, n) {
                    for (var i in ((e = e || (n.constructor === Array ? [] : {})), n)) e[i] = n[i] && n[i].constructor === Object ? t(e[i], n[i]) : n[i];
                    return e;
                  };
                for (e[0] = "object" === a(e[0]) ? e[0] : {}; t < e.length; t++) "object" === a(e[t]) && n(e[0], e[t]);
                return e[0];
              }),
              (f.v = "1.0.7"),
              (f.ie = ((h = navigator.userAgent.toLowerCase()), !(!window.ActiveXObject && !("ActiveXObject" in window)) && ((h.match(/msie\s(\d+)/) || [])[1] || "11"))),
              (f.layui = m),
              (f.getPath = m.cache.dir),
              (f.stope = m.stope),
              (f.each = function () {
                return m.each.apply(m, arguments), this;
              }),
              (f.digit = function (t, e, n) {
                var i = "";
                e = e || 2;
                for (var a = (t = String(t)).length; a < e; a++) i += "0";
                return t < Math.pow(10, e) ? i + (0 | t) : t;
              }),
              (f.elem = function (t, e) {
                var n = y.createElement(t);
                return (
                  f.each(e || {}, function (t, e) {
                    n.setAttribute(t, e);
                  }),
                  n
                );
              }),
              (f.hasScrollbar = function () {
                return y.body.scrollHeight > (window.innerHeight || y.documentElement.clientHeight);
              }),
              (f.position = function (t, e, n) {
                if (e) {
                  (n = n || {}), (t !== y && t !== f("body")[0]) || (n.clickType = "right");
                  var i,
                    a =
                      "right" === n.clickType
                        ? {
                            left: (i = n.e || window.event || {}).clientX,
                            top: i.clientY,
                            right: i.clientX,
                            bottom: i.clientY,
                          }
                        : t.getBoundingClientRect(),
                    r = e.offsetWidth,
                    o = e.offsetHeight,
                    s = function (t) {
                      return (t = t ? "scrollLeft" : "scrollTop"), y.body[t] | y.documentElement[t];
                    },
                    c = function (t) {
                      return y.documentElement[t ? "clientWidth" : "clientHeight"];
                    },
                    l = a.left,
                    u = a.bottom;
                  l + r + 5 > c("width") && (l = c("width") - r - 5), u + o + 5 > c() && (a.top > o + 5 ? (u = a.top - o - 10) : "right" === n.clickType && (u = c() - o - 10) < 0 && (u = 0));
                  var d = n.position;
                  if ((d && (e.style.position = d), (e.style.left = "".concat(l + ("fixed" === d ? 0 : s(1)), "px")), (e.style.top = "".concat(u + ("fixed" === d ? 0 : s()), "px")), !f.hasScrollbar())) {
                    var h = e.getBoundingClientRect();
                    !n.SYSTEM_RELOAD &&
                      h.bottom + 5 > c() &&
                      ((n.SYSTEM_RELOAD = !0),
                      setTimeout(function () {
                        f.position(t, e, n);
                      }, 50));
                  }
                }
              }),
              (f.options = function (t, e) {
                var n = f(t),
                  i = e || "lay-options";
                try {
                  return new Function("return ".concat(n.attr(i) || "{}"))();
                } catch (t) {
                  return hint.error("parseerror：".concat(t), "error"), {};
                }
              }),
              (f.isTopElem = function (t) {
                var e = [y, f("body")[0]],
                  n = !1;
                return (
                  f.each(e, function (e, i) {
                    if (i === t) return (n = !0);
                  }),
                  n
                );
              }),
              (p.addStr = function (t, e) {
                return (
                  (t = t.replace(/\s+/, " ")),
                  (e = e.replace(/\s+/, " ").split(" ")),
                  f.each(e, function (e, n) {
                    new RegExp("\\b".concat(n, "\\b")).test(t) || (t = "".concat(t, " ").concat(n));
                  }),
                  t.replace(/^\s|\s$/, "")
                );
              }),
              (p.removeStr = function (t, e) {
                return (
                  (t = t.replace(/\s+/, " ")),
                  (e = e.replace(/\s+/, " ").split(" ")),
                  f.each(e, function (e, n) {
                    var i = new RegExp("\\b".concat(n, "\\b"));
                    i.test(t) && (t = t.replace(i, ""));
                  }),
                  t.replace(/\s+/, " ").replace(/^\s|\s$/, "")
                );
              }),
              (p.prototype.find = function (t) {
                var e = this,
                  n = 0,
                  i = [],
                  r = "object" === a(t);
                return (
                  this.each(function (a, o) {
                    for (var s = r ? o.contains(t) : o.querySelectorAll(t || null); n < s.length; n++) i.push(s[n]);
                    e.shift();
                  }),
                  r || (e.selector = (e.selector ? "".concat(e.selector, " ") : "") + t),
                  f.each(i, function (t, n) {
                    e.push(n);
                  }),
                  e
                );
              }),
              (p.prototype.each = function (t) {
                return f.each.call(this, this, t);
              }),
              (p.prototype.addClass = function (t, e) {
                return this.each(function (n, i) {
                  i.className = p[e ? "removeStr" : "addStr"](i.className, t);
                });
              }),
              (p.prototype.removeClass = function (t) {
                return this.addClass(t, !0);
              }),
              (p.prototype.hasClass = function (t) {
                var e = !1;
                return (
                  this.each(function (n, i) {
                    new RegExp("\\b".concat(t, "\\b")).test(i.className) && (e = !0);
                  }),
                  e
                );
              }),
              (p.prototype.css = function (t, e) {
                var n = this,
                  i = function (t) {
                    return isNaN(t) ? t : "".concat(t, "px");
                  };
                return "string" == typeof t && void 0 === e
                  ? (function () {
                      if (n.length > 0) return n[0].style[t];
                    })()
                  : n.each(function (n, r) {
                      "object" === a(t)
                        ? f.each(t, function (t, e) {
                            r.style[t] = i(e);
                          })
                        : (r.style[t] = i(e));
                    });
              }),
              (p.prototype.width = function (t) {
                var e = this;
                return void 0 === t
                  ? (function () {
                      if (e.length > 0) return e[0].offsetWidth;
                    })()
                  : e.each(function (n, i) {
                      e.css("width", t);
                    });
              }),
              (p.prototype.height = function (t) {
                var e = this;
                return void 0 === t
                  ? (function () {
                      if (e.length > 0) return e[0].offsetHeight;
                    })()
                  : e.each(function (n, i) {
                      e.css("height", t);
                    });
              }),
              (p.prototype.attr = function (t, e) {
                var n = this;
                return void 0 === e
                  ? (function () {
                      if (n.length > 0) return n[0].getAttribute(t);
                    })()
                  : n.each(function (n, i) {
                      i.setAttribute(t, e);
                    });
              }),
              (p.prototype.removeAttr = function (t) {
                return this.each(function (e, n) {
                  n.removeAttribute(t);
                });
              }),
              (p.prototype.html = function (t) {
                var e = this;
                return void 0 === t
                  ? (function () {
                      if (e.length > 0) return e[0].innerHTML;
                    })()
                  : this.each(function (e, n) {
                      n.innerHTML = t;
                    });
              }),
              (p.prototype.val = function (t) {
                var e = this;
                return void 0 === t
                  ? (function () {
                      if (e.length > 0) return e[0].value;
                    })()
                  : this.each(function (e, n) {
                      n.value = t;
                    });
              }),
              (p.prototype.append = function (t) {
                return this.each(function (e, n) {
                  "object" === a(t) ? n.appendChild(t) : (n.innerHTML += t);
                });
              }),
              (p.prototype.remove = function (t) {
                return this.each(function (e, n) {
                  t ? n.removeChild(t) : n.parentNode.removeChild(n);
                });
              }),
              (p.prototype.on = function (t, e) {
                return this.each(function (n, i) {
                  i.attachEvent
                    ? i.attachEvent("on".concat(t), function (t) {
                        (t.target = t.srcElement), e.call(i, t);
                      })
                    : i.addEventListener(t, e, !1);
                });
              }),
              (p.prototype.off = function (t, e) {
                return this.each(function (n, i) {
                  i.detachEvent ? i.detachEvent("on".concat(t), e) : i.removeEventListener(t, e, !1);
                });
              }),
              (window.lay = f),
              window.layui &&
                m.define &&
                m.define(function (t) {
                  t("lay", f);
                });
          })(window, window.document),
            (function (a, r) {
              var o = a.layui && layui.define,
                s = {
                  getPath: a.lay && lay.getPath ? lay.getPath : "",
                  link: function (t, e, n) {
                    e();
                  },
                },
                c = a.LAYUI_GLOBAL || {},
                l = {
                  v: "5.3.1",
                  config: {},
                  index: a.laydate && a.laydate.v ? 1e5 : 0,
                  path: c.laydate_dir || s.getPath,
                  set: function (t) {
                    var e = this;
                    return (e.config = lay.extend({}, e.config, t)), e;
                  },
                  ready: function (t) {
                    var e = "laydate",
                      n = ""
                        .concat(o ? "modules/laydate/" : "theme/", "default/laydate.css?v=")
                        .concat(l.v)
                        .concat("");
                    return o ? layui.addcss(n, t, e) : s.link(n, t, e), this;
                  },
                },
                u = function t() {
                  var e = this,
                    n = e.config.id;
                  return (
                    (t.that[n] = e),
                    {
                      hint: function (t) {
                        e.hint.call(e, t);
                      },
                      config: e.config,
                    }
                  );
                },
                d = "laydate",
                h = "layui-this",
                m = "laydate-disabled",
                y = [100, 2e5],
                f = "layui-laydate-static",
                p = "layui-laydate-list",
                v = "layui-laydate-hint",
                g = ".laydate-btns-confirm",
                b = "laydate-time-text",
                w = "laydate-btns-time",
                _ = "layui-laydate-preview",
                T = function (t) {
                  var e = this;
                  (e.index = ++l.index),
                    (e.config = lay.extend({}, e.config, l.config, t)),
                    ((t = e.config).id = "id" in t ? t.id : e.index),
                    l.ready(function () {
                      e.init();
                    });
                },
                k = "yyyy|y|MM|M|dd|d|HH|H|mm|m|ss|s";
              (u.formatArr = function (t) {
                return (t || "").match(new RegExp("".concat(k, "|."), "g")) || [];
              }),
                (T.isLeapYear = function (t) {
                  return (t % 4 == 0 && t % 100 != 0) || t % 400 == 0;
                }),
                (T.prototype.config = {
                  type: "date",
                  range: !1,
                  format: "yyyy-MM-dd",
                  value: null,
                  isInitValue: !0,
                  min: "1900-1-1",
                  max: "2099-12-31",
                  trigger: "click",
                  show: !1,
                  showBottom: !0,
                  isPreview: !0,
                  btns: ["clear", "now", "confirm"],
                  lang: "cn",
                  theme: "default",
                  position: null,
                  calendar: !1,
                  mark: {},
                  zIndex: null,
                  done: null,
                  change: null,
                }),
                (T.prototype.lang = function () {
                  var t = {
                    cn: {
                      weeks: ["日", "一", "二", "三", "四", "五", "六"],
                      time: ["时", "分", "秒"],
                      timeTips: "选择时间",
                      startTime: "开始时间",
                      endTime: "结束时间",
                      dateTips: "返回日期",
                      month: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
                      tools: { confirm: "确定", clear: "清空", now: "现在" },
                      timeout: "结束时间不能早于开始时间<br>请重新选择",
                      invalidDate: "不在有效日期或时间范围内",
                      formatError: ["日期格式不合法<br>必须遵循下述格式：<br>", "<br>已为你重置"],
                      preview: "当前选中的结果",
                    },
                    en: {
                      weeks: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                      time: ["Hours", "Minutes", "Seconds"],
                      timeTips: "Select Time",
                      startTime: "Start Time",
                      endTime: "End Time",
                      dateTips: "Select Date",
                      month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      tools: { confirm: "Confirm", clear: "Clear", now: "Now" },
                      timeout: "End time cannot be less than start Time<br>Please re-select",
                      invalidDate: "Invalid date",
                      formatError: ["The date format error<br>Must be followed：<br>", "<br>It has been reset"],
                      preview: "The selected result",
                    },
                  };
                  return t[this.config.lang] || t.cn;
                }),
                (T.prototype.init = function () {
                  var t = this,
                    e = t.config,
                    n = "static" === e.position,
                    i = {
                      year: "yyyy",
                      month: "yyyy-MM",
                      date: "yyyy-MM-dd",
                      time: "HH:mm:ss",
                      datetime: "yyyy-MM-dd HH:mm:ss",
                    };
                  (e.elem = lay(e.elem)),
                    (e.eventElem = lay(e.eventElem)),
                    e.elem[0] &&
                      ((t.rangeStr = e.range ? ("string" == typeof e.range ? e.range : "-") : ""),
                      e.range && e.range.constructor === Array && (t.rangeElem = [lay(e.range[0]), lay(e.range[1])]),
                      i[e.type] || (a.console && console.error && console.error("laydate type error:'".concat(e.type, "' is not supported")), (e.type = "date")),
                      e.format === i.date && (e.format = i[e.type] || i.date),
                      (t.format = u.formatArr(e.format)),
                      (t.EXP_IF = ""),
                      (t.EXP_SPLIT = ""),
                      lay.each(t.format, function (e, n) {
                        var i = new RegExp(k).test(n) ? "\\d{".concat(new RegExp(k).test(t.format[0 === e ? e + 1 : e - 1] || "") ? (/^yyyy|y$/.test(n) ? 4 : n.length) : /^yyyy$/.test(n) ? "1,4" : /^y$/.test(n) ? "1,308" : "1,2", "}") : "\\".concat(n);
                        (t.EXP_IF += i), (t.EXP_SPLIT = "".concat(t.EXP_SPLIT, "(").concat(i, ")"));
                      }),
                      (t.EXP_IF_ONE = new RegExp("^".concat(t.EXP_IF, "$"))),
                      (t.EXP_IF = new RegExp("^".concat(e.range ? "".concat(t.EXP_IF, "\\s\\").concat(t.rangeStr, "\\s").concat(t.EXP_IF) : t.EXP_IF, "$"))),
                      (t.EXP_SPLIT = new RegExp("^".concat(t.EXP_SPLIT, "$"), "")),
                      t.isInput(e.elem[0]) || ("focus" === e.trigger && (e.trigger = "click")),
                      e.elem.attr("lay-key") || (e.elem.attr("lay-key", t.index), e.eventElem.attr("lay-key", t.index)),
                      (e.mark = lay.extend(
                        {},
                        e.calendar && "cn" === e.lang
                          ? {
                              "0-1-1": "元旦",
                              "0-2-14": "情人",
                              "0-3-8": "妇女",
                              "0-3-12": "植树",
                              "0-4-1": "愚人",
                              "0-5-1": "劳动",
                              "0-5-4": "青年",
                              "0-6-1": "儿童",
                              "0-9-10": "教师",
                              "0-9-18": "国耻",
                              "0-10-1": "国庆",
                              "0-12-25": "圣诞",
                            }
                          : {},
                        e.mark
                      )),
                      lay.each(["min", "max"], function (t, n) {
                        var i = [],
                          a = [];
                        if ("number" == typeof e[n]) {
                          var r = e[n],
                            o = new Date().getTime(),
                            s = 864e5,
                            c = new Date(r ? (r < s ? o + r * s : r) : o);
                          (i = [c.getFullYear(), c.getMonth() + 1, c.getDate()]), r < s || (a = [c.getHours(), c.getMinutes(), c.getSeconds()]);
                        } else (i = (e[n].match(/\d+-\d+-\d+/) || [""])[0].split("-")), (a = (e[n].match(/\d+:\d+:\d+/) || [""])[0].split(":"));
                        e[n] = {
                          year: 0 | i[0] || new Date().getFullYear(),
                          month: i[1] ? (0 | i[1]) - 1 : new Date().getMonth(),
                          date: 0 | i[2] || new Date().getDate(),
                          hours: 0 | a[0],
                          minutes: 0 | a[1],
                          seconds: 0 | a[2],
                        };
                      }),
                      (t.elemID = "layui-laydate".concat(e.elem.attr("lay-key"))),
                      (e.show || n) && t.render(),
                      n || t.events(),
                      e.value && e.isInitValue && (e.value.constructor === Date ? t.setValue(t.parse(0, t.systemDate(e.value))) : t.setValue(e.value)));
                }),
                (T.prototype.render = function () {
                  var t,
                    e,
                    n = this,
                    i = n.config,
                    a = n.lang(),
                    o = "static" === i.position,
                    s = (n.elem = lay.elem("div", {
                      id: n.elemID,
                      class: ["layui-laydate", i.range ? " layui-laydate-range" : "", o ? " ".concat(f) : "", i.theme && "default" !== i.theme && !/^#/.test(i.theme) ? " laydate-theme-".concat(i.theme) : ""].join(""),
                    })),
                    c = (n.elemMain = []),
                    u = (n.elemHeader = []),
                    d = (n.elemCont = []),
                    h = (n.table = []),
                    m = (n.footer = lay.elem("div", { class: "layui-laydate-footer" }));
                  if (
                    (i.zIndex && (s.style.zIndex = i.zIndex),
                    lay.each(new Array(2), function (t) {
                      if (!i.range && t > 0) return !0;
                      var e = lay.elem("div", { class: "layui-laydate-header" }),
                        n = [
                          (function () {
                            var t = lay.elem("i", { class: "layui-icon laydate-icon laydate-prev-y" });
                            return (t.innerHTML = "<<"), t;
                          })(),
                          (function () {
                            var t = lay.elem("i", { class: "layui-icon laydate-icon laydate-prev-m" });
                            return (t.innerHTML = "<"), t;
                          })(),
                          (function () {
                            var t = lay.elem("div", { class: "laydate-set-ym" }),
                              e = lay.elem("span"),
                              n = lay.elem("span");
                            return t.appendChild(e), t.appendChild(n), t;
                          })(),
                          (function () {
                            var t = lay.elem("i", { class: "layui-icon laydate-icon laydate-next-m" });
                            return (t.innerHTML = ">"), t;
                          })(),
                          (function () {
                            var t = lay.elem("i", { class: "layui-icon laydate-icon laydate-next-y" });
                            return (t.innerHTML = ">>"), t;
                          })(),
                        ],
                        r = lay.elem("div", { class: "layui-laydate-content" }),
                        o = lay.elem("table"),
                        s = lay.elem("thead"),
                        l = lay.elem("tr");
                      lay.each(n, function (t, n) {
                        e.appendChild(n);
                      }),
                        s.appendChild(l),
                        lay.each(new Array(6), function (t) {
                          var e = o.insertRow(0);
                          lay.each(new Array(7), function (n) {
                            if (0 === t) {
                              var i = lay.elem("th");
                              (i.innerHTML = a.weeks[n]), l.appendChild(i);
                            }
                            e.insertCell(n);
                          });
                        }),
                        o.insertBefore(s, o.children[0]),
                        r.appendChild(o),
                        (c[t] = lay.elem("div", { class: "layui-laydate-main laydate-main-list-".concat(t) })),
                        c[t].appendChild(e),
                        c[t].appendChild(r),
                        u.push(n),
                        d.push(r),
                        h.push(o);
                    }),
                    lay(m).html(
                      ((t = []),
                      (e = []),
                      "datetime" === i.type && t.push('<span lay-type="datetime" class="'.concat(w, '">').concat(a.timeTips, "</span>")),
                      (i.range || "datetime" !== i.type) && t.push('<span class="'.concat(_, '" title="').concat(a.preview, '"></span>')),
                      lay.each(i.btns, function (t, n) {
                        var r = a.tools[n] || "btn";
                        (i.range && "now" === n) || (o && "clear" === n && (r = "cn" === i.lang ? "重置" : "Reset"), e.push('<span lay-type="'.concat(n, '" class="laydate-btns-').concat(n, '">').concat(r, "</span>")));
                      }),
                      t.push('<div class="laydate-footer-btns">'.concat(e.join(""), "</div>")),
                      t.join(""))
                    ),
                    lay.each(c, function (t, e) {
                      s.appendChild(e);
                    }),
                    i.showBottom && s.appendChild(m),
                    /^#/.test(i.theme))
                  ) {
                    var y = lay.elem("style"),
                      p = ["#{{id}} .layui-laydate-header{background-color:{{theme}};}", "#{{id}} .layui-this{background-color:{{theme}} !important;}"]
                        .join("")
                        .replace(/{{id}}/g, n.elemID)
                        .replace(/{{theme}}/g, i.theme);
                    "styleSheet" in y ? (y.setAttribute("type", "text/css"), (y.styleSheet.cssText = p)) : (y.innerHTML = p), lay(s).addClass("laydate-theme-molv"), s.appendChild(y);
                  }
                  (l.thisId = i.id), n.remove(T.thisElemDate), o ? i.elem.append(s) : (r.body.appendChild(s), n.position()), n.checkDate().calendar(null, 0, "init"), n.changeEvent(), (T.thisElemDate = n.elemID), "function" == typeof i.ready && i.ready(lay.extend({}, i.dateTime, { month: i.dateTime.month + 1 })), n.preview();
                }),
                (T.prototype.remove = function (t) {
                  var e = this,
                    n = (e.config, lay("#".concat(t || e.elemID)));
                  return n[0]
                    ? (n.hasClass(f) ||
                        e.checkDate(function () {
                          n.remove();
                        }),
                      e)
                    : e;
                }),
                (T.prototype.position = function () {
                  var t = this,
                    e = t.config;
                  return lay.position(t.bindElem || e.elem[0], t.elem, { position: e.position }), t;
                }),
                (T.prototype.hint = function (t) {
                  var e = this,
                    n = (e.config, lay.elem("div", { class: v }));
                  e.elem &&
                    ((n.innerHTML = t || ""),
                    lay(e.elem).find(".".concat(v)).remove(),
                    e.elem.appendChild(n),
                    clearTimeout(e.hinTimer),
                    (e.hinTimer = setTimeout(function () {
                      lay(e.elem).find(".".concat(v)).remove();
                    }, 3e3)));
                }),
                (T.prototype.getAsYM = function (t, e, n) {
                  return n ? e-- : e++, e < 0 && ((e = 11), t--), e > 11 && ((e = 0), t++), [t, e];
                }),
                (T.prototype.systemDate = function (t) {
                  var e = t || new Date();
                  return {
                    year: e.getFullYear(),
                    month: e.getMonth(),
                    date: e.getDate(),
                    hours: t ? t.getHours() : 0,
                    minutes: t ? t.getMinutes() : 0,
                    seconds: t ? t.getSeconds() : 0,
                  };
                }),
                (T.prototype.checkDate = function (t) {
                  var e,
                    n,
                    i = this,
                    a = (new Date(), i.config),
                    r = i.lang(),
                    o = (a.dateTime = a.dateTime || i.systemDate()),
                    s = i.bindElem || a.elem[0],
                    c =
                      (i.isInput(s),
                      (function () {
                        if (i.rangeElem) {
                          var t = [i.rangeElem[0].val(), i.rangeElem[1].val()];
                          if (t[0] && t[1]) return t.join(" ".concat(i.rangeStr, " "));
                        }
                        return i.isInput(s) ? s.value : "static" === a.position ? "" : lay(s).attr("lay-date");
                      })()),
                    u = function (t) {
                      t.year > y[1] && ((t.year = y[1]), (n = !0)), t.month > 11 && ((t.month = 11), (n = !0)), t.hours > 23 && ((t.hours = 0), (n = !0)), t.minutes > 59 && ((t.minutes = 0), t.hours++, (n = !0)), t.seconds > 59 && ((t.seconds = 0), t.minutes++, (n = !0)), (e = l.getEndDate(t.month + 1, t.year)), t.date > e && ((t.date = e), (n = !0));
                    },
                    d = function (t, e, r) {
                      var o = ["startTime", "endTime"];
                      (e = (e.match(i.EXP_SPLIT) || []).slice(1)),
                        (r = r || 0),
                        a.range && (i[o[r]] = i[o[r]] || {}),
                        lay.each(i.format, function (s, c) {
                          var l = parseFloat(e[s]);
                          e[s].length < c.length && (n = !0), /yyyy|y/.test(c) ? (l < y[0] && ((l = y[0]), (n = !0)), (t.year = l)) : /MM|M/.test(c) ? (l < 1 && ((l = 1), (n = !0)), (t.month = l - 1)) : /dd|d/.test(c) ? (l < 1 && ((l = 1), (n = !0)), (t.date = l)) : /HH|H/.test(c) ? (l < 1 && ((l = 0), (n = !0)), (t.hours = l), a.range && (i[o[r]].hours = l)) : /mm|m/.test(c) ? (l < 1 && ((l = 0), (n = !0)), (t.minutes = l), a.range && (i[o[r]].minutes = l)) : /ss|s/.test(c) && (l < 1 && ((l = 0), (n = !0)), (t.seconds = l), a.range && (i[o[r]].seconds = l));
                        }),
                        u(t);
                    };
                  if ("limit" === t) return u(o), i;
                  "string" == typeof (c = c || a.value) && (c = c.replace(/\s+/g, " ").replace(/^\s|\s$/g, ""));
                  var h = function () {
                    a.range &&
                      (i.endDate =
                        i.endDate ||
                        lay.extend(
                          {},
                          a.dateTime,
                          (function () {
                            var t = {},
                              e = a.dateTime,
                              n = i.getAsYM(e.year, e.month);
                            return "year" === a.type ? (t.year = e.year + 1) : "time" !== a.type && ((t.year = n[0]), (t.month = n[1])), ("datetime" !== a.type && "time" !== a.type) || ((t.hours = 23), (t.minutes = t.seconds = 59)), t;
                          })()
                        ));
                  };
                  h(),
                    "string" == typeof c && c
                      ? i.EXP_IF.test(c)
                        ? a.range
                          ? ((c = c.split(" ".concat(i.rangeStr, " "))),
                            lay.each([a.dateTime, i.endDate], function (t, e) {
                              d(e, c[t], t);
                            }))
                          : d(o, c)
                        : (i.hint(r.formatError[0] + (a.range ? "".concat(a.format, " ").concat(i.rangeStr, " ").concat(a.format) : a.format) + r.formatError[1]), (n = !0))
                      : c && c.constructor === Date
                      ? (a.dateTime = i.systemDate(c))
                      : ((a.dateTime = i.systemDate()), delete i.startTime, delete i.endDate, h(), delete i.endTime),
                    (function () {
                      if (i.rangeElem) {
                        var t = [i.rangeElem[0].val(), i.rangeElem[1].val()],
                          e = [a.dateTime, i.endDate];
                        lay.each(t, function (t, n) {
                          i.EXP_IF_ONE.test(n) && d(e[t], n, t);
                        });
                      }
                    })(),
                    u(o),
                    a.range && u(i.endDate),
                    n && c && i.setValue(a.range ? (i.endDate ? i.parse() : "") : i.parse());
                  var m = function (t) {
                    return i.newDate(t).getTime();
                  };
                  return (m(o) > m(a.max) || m(o) < m(a.min)) && (o = a.dateTime = lay.extend({}, a.min)), a.range && (m(i.endDate) < m(a.min) || m(i.endDate) > m(a.max)) && (i.endDate = lay.extend({}, a.max)), t && t(), i;
                }),
                (T.prototype.mark = function (t, e) {
                  var n,
                    i = this.config;
                  return (
                    lay.each(i.mark, function (t, i) {
                      var a = t.split("-");
                      (a[0] != e[0] && 0 != a[0]) || (a[1] != e[1] && 0 != a[1]) || a[2] != e[2] || (n = i || e[2]);
                    }),
                    n && t.html('<span class="laydate-day-mark">'.concat(n, "</span>")),
                    this
                  );
                }),
                (T.prototype.limit = function (t, e, n, i) {
                  var a,
                    r = this,
                    o = r.config,
                    s = {},
                    c = o[n > 41 ? "endDate" : "dateTime"],
                    l = lay.extend({}, c, e || {});
                  return (
                    lay.each({ now: l, min: o.min, max: o.max }, function (t, e) {
                      var n;
                      s[t] = r
                        .newDate(
                          lay.extend(
                            {
                              year: e.year,
                              month: e.month,
                              date: e.date,
                            },
                            ((n = {}),
                            lay.each(i, function (t, i) {
                              n[i] = e[i];
                            }),
                            n)
                          )
                        )
                        .getTime();
                    }),
                    (a = s.now < s.min || s.now > s.max),
                    t && t[a ? "addClass" : "removeClass"](m),
                    a
                  );
                }),
                (T.prototype.thisDateTime = function (t) {
                  var e = this.config;
                  return t ? this.endDate : e.dateTime;
                }),
                (T.prototype.calendar = function (t, e, n) {
                  var i,
                    a,
                    r,
                    o = this,
                    s = o.config,
                    c = ((e = e ? 1 : 0), t || o.thisDateTime(e)),
                    u = new Date(),
                    d = o.lang(),
                    m = "date" !== s.type && "datetime" !== s.type,
                    f = lay(o.table[e]).find("td"),
                    p = lay(o.elemHeader[e][2]).find("span");
                  return (
                    c.year < y[0] && ((c.year = y[0]), o.hint(d.invalidDate)),
                    c.year > y[1] && ((c.year = y[1]), o.hint(d.invalidDate)),
                    o.firstDate || (o.firstDate = lay.extend({}, c)),
                    u.setFullYear(c.year, c.month, 1),
                    (i = u.getDay()),
                    (a = l.getEndDate(c.month || 12, c.year)),
                    (r = l.getEndDate(c.month + 1, c.year)),
                    lay.each(f, function (t, e) {
                      var n = [c.year, c.month],
                        s = 0;
                      (e = lay(e)).removeAttr("class"),
                        t < i ? ((s = a - i + t), e.addClass("laydate-day-prev"), (n = o.getAsYM(c.year, c.month, "sub"))) : t >= i && t < r + i ? 1 + (s = t - i) === c.date && e.addClass(h) : ((s = t - r - i), e.addClass("laydate-day-next"), (n = o.getAsYM(c.year, c.month))),
                        n[1]++,
                        (n[2] = s + 1),
                        e.attr("lay-ymd", n.join("-")).html(n[2]),
                        o.mark(e, n).limit(
                          e,
                          {
                            year: n[0],
                            month: n[1] - 1,
                            date: n[2],
                          },
                          t
                        );
                    }),
                    lay(p[0]).attr("lay-ym", "".concat(c.year, "-").concat(c.month + 1)),
                    lay(p[1]).attr("lay-ym", "".concat(c.year, "-").concat(c.month + 1)),
                    "cn" === s.lang
                      ? (lay(p[0]).attr("lay-type", "year").html("".concat(c.year, " 年")),
                        lay(p[1])
                          .attr("lay-type", "month")
                          .html("".concat(c.month + 1, " 月")))
                      : (lay(p[0]).attr("lay-type", "month").html(d.month[c.month]), lay(p[1]).attr("lay-type", "year").html(c.year)),
                    m &&
                      (s.range
                        ? t &&
                          ((o.listYM = [
                            [s.dateTime.year, s.dateTime.month + 1],
                            [o.endDate.year, o.endDate.month + 1],
                          ]),
                          o.list(s.type, 0).list(s.type, 1),
                          "time" === s.type ? o.setBtnStatus("时间", lay.extend({}, o.systemDate(), o.startTime), lay.extend({}, o.systemDate(), o.endTime)) : o.setBtnStatus(!0))
                        : ((o.listYM = [[c.year, c.month + 1]]), o.list(s.type, 0))),
                    s.range && "init" === n && !t && o.calendar(o.endDate, 1),
                    s.range || o.limit(lay(o.footer).find(g), null, 0, ["hours", "minutes", "seconds"]),
                    o.setBtnStatus(),
                    o
                  );
                }),
                (T.prototype.list = function (t, e) {
                  var n = this,
                    i = n.config,
                    a = i.dateTime,
                    r = n.lang(),
                    o = i.range && "date" !== i.type && "datetime" !== i.type,
                    s = lay.elem("ul", {
                      class: "".concat(p, " ").concat(
                        {
                          year: "laydate-year-list",
                          month: "laydate-month-list",
                          time: "laydate-time-list",
                        }[t]
                      ),
                    }),
                    c = n.elemHeader[e],
                    l = lay(c[2]).find("span"),
                    u = n.elemCont[e || 0],
                    d = lay(u).find(".".concat(p))[0],
                    y = "cn" === i.lang,
                    f = y ? "年" : "",
                    v = n.listYM[e] || {},
                    _ = ["hours", "minutes", "seconds"],
                    T = ["startTime", "endTime"][e];
                  if ((v[0] < 1 && (v[0] = 1), "year" === t)) {
                    var k,
                      E = (k = v[0] - 7);
                    E < 1 && (E = k = 1),
                      lay.each(new Array(15), function (t) {
                        var a = lay.elem("li", { "lay-ym": k }),
                          r = { year: k };
                        k == v[0] && lay(a).addClass(h), (a.innerHTML = k + f), s.appendChild(a), k < n.firstDate.year ? ((r.month = i.min.month), (r.date = i.min.date)) : k >= n.firstDate.year && ((r.month = i.max.month), (r.date = i.max.date)), n.limit(lay(a), r, e), k++;
                      }),
                      lay(l[y ? 0 : 1])
                        .attr("lay-ym", "".concat(k - 8, "-").concat(v[1]))
                        .html("".concat(E + f, " - ").concat(k - 1 + f));
                  } else if ("month" === t)
                    lay.each(new Array(12), function (t) {
                      var a = lay.elem("li", { "lay-ym": t }),
                        o = { year: v[0], month: t };
                      t + 1 == v[1] && lay(a).addClass(h), (a.innerHTML = r.month[t] + (y ? "月" : "")), s.appendChild(a), v[0] < n.firstDate.year ? (o.date = i.min.date) : v[0] >= n.firstDate.year && (o.date = i.max.date), n.limit(lay(a), o, e);
                    }),
                      lay(l[y ? 0 : 1])
                        .attr("lay-ym", "".concat(v[0], "-").concat(v[1]))
                        .html(v[0] + f);
                  else if ("time" === t) {
                    var L = function () {
                      lay(s)
                        .find("ol")
                        .each(function (t, i) {
                          lay(i)
                            .find("li")
                            .each(function (i, a) {
                              n.limit(
                                lay(a),
                                [
                                  { hours: i },
                                  { hours: n[T].hours, minutes: i },
                                  {
                                    hours: n[T].hours,
                                    minutes: n[T].minutes,
                                    seconds: i,
                                  },
                                ][t],
                                e,
                                [["hours"], ["hours", "minutes"], ["hours", "minutes", "seconds"]][t]
                              );
                            });
                        }),
                        i.range || n.limit(lay(n.footer).find(g), n[T], 0, ["hours", "minutes", "seconds"]);
                    };
                    i.range ? n[T] || (n[T] = "startTime" === T ? a : n.endDate) : (n[T] = a),
                      lay.each([24, 60, 60], function (t, e) {
                        var i = lay.elem("li"),
                          a = ["<p>".concat(r.time[t], "</p><ol>")];
                        lay.each(new Array(e), function (e) {
                          a.push("<li".concat(n[T][_[t]] === e ? ' class="'.concat(h, '"') : "", ">").concat(lay.digit(e, 2), "</li>"));
                        }),
                          (i.innerHTML = "".concat(a.join(""), "</ol>")),
                          s.appendChild(i);
                      }),
                      L();
                  }
                  if ((d && u.removeChild(d), u.appendChild(s), "year" === t || "month" === t))
                    lay(n.elemMain[e]).addClass("laydate-ym-show"),
                      lay(s)
                        .find("li")
                        .on("click", function () {
                          var r = 0 | lay(this).attr("lay-ym");
                          lay(this).hasClass(m) || (0 === e ? ((a[t] = r), n.limit(lay(n.footer).find(g), null, 0)) : (n.endDate[t] = r), "year" === i.type || "month" === i.type ? (lay(s).find(".".concat(h)).removeClass(h), lay(this).addClass(h), "month" === i.type && "year" === t && ((n.listYM[e][0] = r), o && ((e ? n.endDate : a).year = r), n.list("month", e))) : (n.checkDate("limit").calendar(null, e), n.closeList()), n.setBtnStatus(), i.range || ((("month" === i.type && "month" === t) || ("year" === i.type && "year" === t)) && n.setValue(n.parse()).remove().done()), n.done(null, "change"), lay(n.footer).find(".".concat(w)).removeClass(m));
                        });
                  else {
                    var x = lay.elem("span", { class: b }),
                      D = function () {
                        lay(s)
                          .find("ol")
                          .each(function (t) {
                            var e = this,
                              i = lay(e).find("li");
                            (e.scrollTop = 30 * (n[T][_[t]] - 2)),
                              e.scrollTop <= 0 &&
                                i.each(function (t, n) {
                                  if (!lay(this).hasClass(m)) return (e.scrollTop = 30 * (t - 2)), !0;
                                });
                          });
                      },
                      S = lay(c[2]).find(".".concat(b));
                    D(),
                      (x.innerHTML = i.range ? [r.startTime, r.endTime][e] : r.timeTips),
                      lay(n.elemMain[e]).addClass("laydate-time-show"),
                      S[0] && S.remove(),
                      c[2].appendChild(x),
                      lay(s)
                        .find("ol")
                        .each(function (t) {
                          var e = this;
                          lay(e)
                            .find("li")
                            .on("click", function () {
                              var r = 0 | this.innerHTML;
                              lay(this).hasClass(m) || (i.range ? (n[T][_[t]] = r) : (a[_[t]] = r), lay(e).find(".".concat(h)).removeClass(h), lay(this).addClass(h), L(), D(), (n.endDate || "time" === i.type) && n.done(null, "change"), n.setBtnStatus());
                            });
                        });
                  }
                  return n;
                }),
                (T.prototype.listYM = []),
                (T.prototype.closeList = function () {
                  var t = this;
                  t.config,
                    lay.each(t.elemCont, function (e, n) {
                      lay(this).find(".".concat(p)).remove(), lay(t.elemMain[e]).removeClass("laydate-ym-show laydate-time-show");
                    }),
                    lay(t.elem).find(".".concat(b)).remove();
                }),
                (T.prototype.setBtnStatus = function (t, e, n) {
                  var i,
                    a = this,
                    r = a.config,
                    o = a.lang(),
                    s = lay(a.footer).find(g);
                  r.range && "time" !== r.type && ((e = e || r.dateTime), (n = n || a.endDate), (i = a.newDate(e).getTime() > a.newDate(n).getTime()), a.limit(null, e) || a.limit(null, n) ? s.addClass(m) : s[i ? "addClass" : "removeClass"](m), t && i && a.hint("string" == typeof t ? o.timeout.replace(/日期/g, t) : o.timeout));
                }),
                (T.prototype.parse = function (t, e) {
                  var n = this,
                    i = n.config,
                    a = e || ("end" == t ? lay.extend({}, n.endDate, n.endTime) : i.range ? lay.extend({}, i.dateTime, n.startTime) : i.dateTime),
                    r = l.parse(a, n.format, 1);
                  return i.range && void 0 === t ? "".concat(r, " ").concat(n.rangeStr, " ").concat(n.parse("end")) : r;
                }),
                (T.prototype.newDate = function (t) {
                  return (t = t || {}), new Date(t.year || 1, t.month || 0, t.date || 1, t.hours || 0, t.minutes || 0, t.seconds || 0);
                }),
                (T.prototype.setValue = function (t) {
                  var e = this,
                    n = e.config,
                    i = e.bindElem || n.elem[0];
                  return "static" === n.position || ((t = t || ""), e.isInput(i) ? lay(i).val(t) : e.rangeElem ? (e.rangeElem[0].val(t ? e.parse("start") : ""), e.rangeElem[1].val(t ? e.parse("end") : "")) : (0 === lay(i).find("*").length && lay(i).html(t), lay(i).attr("lay-date", t))), e;
                }),
                (T.prototype.preview = function () {
                  var t = this,
                    e = t.config;
                  if (e.isPreview) {
                    var n = lay(t.elem).find(".".concat(_)),
                      i = e.range ? (t.endDate ? t.parse() : "") : t.parse();
                    n.html(i).css({ color: "#5FB878", "font-size": "14px;" }),
                      setTimeout(function () {
                        n.css({ color: "#666", "font-size": "12px;" });
                      }, 300);
                  }
                }),
                (T.prototype.done = function (t, e) {
                  var n = this,
                    i = n.config,
                    a = lay.extend({}, lay.extend(i.dateTime, n.startTime)),
                    r = lay.extend({}, lay.extend(n.endDate, n.endTime));
                  return (
                    lay.each([a, r], function (t, e) {
                      "month" in e && lay.extend(e, { month: e.month + 1 });
                    }),
                    n.preview(),
                    (t = t || [n.parse(), a, r]),
                    "function" == typeof i[e || "done"] && i[e || "done"].apply(i, t),
                    n
                  );
                }),
                (T.prototype.choose = function (t, e) {
                  var n = this,
                    i = n.config,
                    a = n.thisDateTime(e),
                    r = (lay(n.elem).find("td"), t.attr("lay-ymd").split("-"));
                  (r = {
                    year: 0 | r[0],
                    month: (0 | r[1]) - 1,
                    date: 0 | r[2],
                  }),
                    t.hasClass(m) ||
                      (lay.extend(a, r),
                      i.range
                        ? (lay.each(["startTime", "endTime"], function (t, e) {
                            n[e] = n[e] || { hours: 0, minutes: 0, seconds: 0 };
                          }),
                          n.calendar(null, e).done(null, "change"))
                        : "static" === i.position
                        ? n.calendar().done().done(null, "change")
                        : "date" === i.type
                        ? n.setValue(n.parse()).remove().done()
                        : "datetime" === i.type && n.calendar().done(null, "change"));
                }),
                (T.prototype.tool = function (t, e) {
                  var n = this,
                    i = n.config,
                    a = n.lang(),
                    r = i.dateTime,
                    o = "static" === i.position,
                    s = {
                      datetime: function () {
                        lay(t).hasClass(m) || (n.list("time", 0), i.range && n.list("time", 1), lay(t).attr("lay-type", "date").html(n.lang().dateTips));
                      },
                      date: function () {
                        n.closeList(), lay(t).attr("lay-type", "datetime").html(n.lang().timeTips);
                      },
                      clear: function () {
                        o && (lay.extend(r, n.firstDate), n.calendar()), i.range && (delete i.dateTime, delete n.endDate, delete n.startTime, delete n.endTime), n.setValue("").remove(), n.done(["", {}, {}]);
                      },
                      now: function () {
                        var t = new Date();
                        lay.extend(r, n.systemDate(), {
                          hours: t.getHours(),
                          minutes: t.getMinutes(),
                          seconds: t.getSeconds(),
                        }),
                          n.setValue(n.parse()).remove(),
                          o && n.calendar(),
                          n.done();
                      },
                      confirm: function () {
                        if (i.range) {
                          if (lay(t).hasClass(m)) return n.hint("time" === i.type ? a.timeout.replace(/日期/g, "时间") : a.timeout);
                        } else if (lay(t).hasClass(m)) return n.hint(a.invalidDate);
                        n.done(), n.setValue(n.parse()).remove();
                      },
                    };
                  s[e] && s[e]();
                }),
                (T.prototype.change = function (t) {
                  var e = this,
                    n = e.config,
                    i = e.thisDateTime(t),
                    a = n.range && ("year" === n.type || "month" === n.type),
                    r = e.elemCont[t || 0],
                    o = e.listYM[t],
                    s = function (s) {
                      var c = lay(r).find(".laydate-year-list")[0],
                        l = lay(r).find(".laydate-month-list")[0];
                      return c && ((o[0] = s ? o[0] - 15 : o[0] + 15), e.list("year", t)), l && (s ? o[0]-- : o[0]++, e.list("month", t)), (c || l) && (lay.extend(i, { year: o[0] }), a && (i.year = o[0]), n.range || e.done(null, "change"), n.range || e.limit(lay(e.footer).find(g), { year: o[0] })), e.setBtnStatus(), c || l;
                    };
                  return {
                    prevYear: function () {
                      s("sub") || (i.year--, e.checkDate("limit").calendar(null, t), e.done(null, "change"));
                    },
                    prevMonth: function () {
                      var n = e.getAsYM(i.year, i.month, "sub");
                      lay.extend(i, {
                        year: n[0],
                        month: n[1],
                      }),
                        e.checkDate("limit").calendar(null, t),
                        e.done(null, "change");
                    },
                    nextMonth: function () {
                      var n = e.getAsYM(i.year, i.month);
                      lay.extend(i, {
                        year: n[0],
                        month: n[1],
                      }),
                        e.checkDate("limit").calendar(null, t),
                        e.done(null, "change");
                    },
                    nextYear: function () {
                      s() || (i.year++, e.checkDate("limit").calendar(null, t), e.done(null, "change"));
                    },
                  };
                }),
                (T.prototype.changeEvent = function () {
                  var t = this;
                  t.config,
                    lay(t.elem)
                      .on("click", function (t) {
                        lay.stope(t);
                      })
                      .on("mousedown", function (t) {
                        lay.stope(t);
                      }),
                    lay.each(t.elemHeader, function (e, n) {
                      lay(n[0]).on("click", function (n) {
                        t.change(e).prevYear();
                      }),
                        lay(n[1]).on("click", function (n) {
                          t.change(e).prevMonth();
                        }),
                        lay(n[2])
                          .find("span")
                          .on("click", function (n) {
                            var i = lay(this),
                              a = i.attr("lay-ym"),
                              r = i.attr("lay-type");
                            a && ((a = a.split("-")), (t.listYM[e] = [0 | a[0], 0 | a[1]]), t.list(r, e), lay(t.footer).find(".".concat(w)).addClass(m));
                          }),
                        lay(n[3]).on("click", function (n) {
                          t.change(e).nextMonth();
                        }),
                        lay(n[4]).on("click", function (n) {
                          t.change(e).nextYear();
                        });
                    }),
                    lay.each(t.table, function (e, n) {
                      lay(n)
                        .find("td")
                        .on("click", function () {
                          t.choose(lay(this), e);
                        });
                    }),
                    lay(t.footer)
                      .find("span")
                      .on("click", function () {
                        var e = lay(this).attr("lay-type");
                        t.tool(this, e);
                      });
                }),
                (T.prototype.isInput = function (t) {
                  return /input|textarea/.test(t.tagName.toLocaleLowerCase());
                }),
                (T.prototype.events = function () {
                  var t = this,
                    e = t.config,
                    n = function (n, i) {
                      n.on(e.trigger, function () {
                        i && (t.bindElem = this), t.render();
                      });
                    };
                  e.elem[0] && !e.elem[0].eventHandler && (n(e.elem, "bind"), n(e.eventElem), (e.elem[0].eventHandler = !0));
                }),
                (u.that = {}),
                (u.getThis = function (t) {
                  var e = u.that[t];
                  return e || hint.error(t ? "".concat(d, " instance with ID '").concat(t, "' not found") : "ID argument required"), e;
                }),
                (s.run = function (t) {
                  t(r)
                    .on("mousedown", function (e) {
                      if (l.thisId) {
                        var n = u.getThis(l.thisId);
                        if (n) {
                          var i = n.config;
                          e.target !== i.elem[0] && e.target !== i.eventElem[0] && e.target !== t(i.closeStop)[0] && n.remove();
                        }
                      }
                    })
                    .on("keydown", function (e) {
                      if (l.thisId) {
                        var n = u.getThis(l.thisId);
                        n && 13 === e.keyCode && t("#".concat(n.elemID))[0] && n.elemID === T.thisElemDate && (e.preventDefault(), t(n.footer).find(g)[0].click());
                      }
                    }),
                    t(a).on("resize", function () {
                      if (l.thisId) {
                        var e = u.getThis(l.thisId);
                        if (e) return !(!e.elem || !t(".layui-laydate")[0]) && void e.position();
                      }
                    });
                }),
                (l.render = function (t) {
                  var e = new T(t);
                  return u.call(e);
                }),
                (l.parse = function (t, e, n) {
                  return (
                    (t = t || {}),
                    "string" == typeof e && (e = u.formatArr(e)),
                    (e = (e || []).concat()),
                    lay.each(e, function (i, a) {
                      /yyyy|y/.test(a) ? (e[i] = lay.digit(t.year, a.length)) : /MM|M/.test(a) ? (e[i] = lay.digit(t.month + (n || 0), a.length)) : /dd|d/.test(a) ? (e[i] = lay.digit(t.date, a.length)) : /HH|H/.test(a) ? (e[i] = lay.digit(t.hours, a.length)) : /mm|m/.test(a) ? (e[i] = lay.digit(t.minutes, a.length)) : /ss|s/.test(a) && (e[i] = lay.digit(t.seconds, a.length));
                    }),
                    e.join("")
                  );
                }),
                (l.getEndDate = function (t, e) {
                  var n = new Date();
                  return n.setFullYear(e || n.getFullYear(), t || n.getMonth() + 1, 1), new Date(n.getTime() - 864e5).getDate();
                }),
                o
                  ? (l.ready(),
                    layui.define("lay", function (t) {
                      (l.path = layui.cache.dir), s.run(lay), t(d, l);
                    }))
                  : void 0 ===
                      (i = function () {
                        return s.run(lay), l;
                      }.call(e, n, e, t)) || (t.exports = i);
            })(window, window.document);
        },
        484: function (t) {
          t.exports = (function () {
            "use strict";
            var t = 6e4,
              e = 36e5,
              n = "millisecond",
              i = "second",
              a = "minute",
              r = "hour",
              o = "day",
              s = "week",
              c = "month",
              l = "quarter",
              u = "year",
              d = "date",
              h = "Invalid Date",
              m = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/,
              y = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g,
              f = {
                name: "en",
                weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"),
                months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"),
                ordinal: function (t) {
                  var e = ["th", "st", "nd", "rd"],
                    n = t % 100;
                  return "[" + t + (e[(n - 20) % 10] || e[n] || e[0]) + "]";
                },
              },
              p = function (t, e, n) {
                var i = String(t);
                return !i || i.length >= e ? t : "" + Array(e + 1 - i.length).join(n) + t;
              },
              v = {
                s: p,
                z: function (t) {
                  var e = -t.utcOffset(),
                    n = Math.abs(e),
                    i = Math.floor(n / 60),
                    a = n % 60;
                  return (e <= 0 ? "+" : "-") + p(i, 2, "0") + ":" + p(a, 2, "0");
                },
                m: function t(e, n) {
                  if (e.date() < n.date()) return -t(n, e);
                  var i = 12 * (n.year() - e.year()) + (n.month() - e.month()),
                    a = e.clone().add(i, c),
                    r = n - a < 0,
                    o = e.clone().add(i + (r ? -1 : 1), c);
                  return +(-(i + (n - a) / (r ? a - o : o - a)) || 0);
                },
                a: function (t) {
                  return t < 0 ? Math.ceil(t) || 0 : Math.floor(t);
                },
                p: function (t) {
                  return (
                    {
                      M: c,
                      y: u,
                      w: s,
                      d: o,
                      D: d,
                      h: r,
                      m: a,
                      s: i,
                      ms: n,
                      Q: l,
                    }[t] ||
                    String(t || "")
                      .toLowerCase()
                      .replace(/s$/, "")
                  );
                },
                u: function (t) {
                  return void 0 === t;
                },
              },
              g = "en",
              b = {};
            b[g] = f;
            var w = function (t) {
                return t instanceof E;
              },
              _ = function t(e, n, i) {
                var a;
                if (!e) return g;
                if ("string" == typeof e) {
                  var r = e.toLowerCase();
                  b[r] && (a = r), n && ((b[r] = n), (a = r));
                  var o = e.split("-");
                  if (!a && o.length > 1) return t(o[0]);
                } else {
                  var s = e.name;
                  (b[s] = e), (a = s);
                }
                return !i && a && (g = a), a || (!i && g);
              },
              T = function (t, e) {
                if (w(t)) return t.clone();
                var n = "object" == typeof e ? e : {};
                return (n.date = t), (n.args = arguments), new E(n);
              },
              k = v;
            (k.l = _),
              (k.i = w),
              (k.w = function (t, e) {
                return T(t, { locale: e.$L, utc: e.$u, x: e.$x, $offset: e.$offset });
              });
            var E = (function () {
                function f(t) {
                  (this.$L = _(t.locale, null, !0)), this.parse(t);
                }

                var p = f.prototype;
                return (
                  (p.parse = function (t) {
                    (this.$d = (function (t) {
                      var e = t.date,
                        n = t.utc;
                      if (null === e) return new Date(NaN);
                      if (k.u(e)) return new Date();
                      if (e instanceof Date) return new Date(e);
                      if ("string" == typeof e && !/Z$/i.test(e)) {
                        var i = e.match(m);
                        if (i) {
                          var a = i[2] - 1 || 0,
                            r = (i[7] || "0").substring(0, 3);
                          return n ? new Date(Date.UTC(i[1], a, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, r)) : new Date(i[1], a, i[3] || 1, i[4] || 0, i[5] || 0, i[6] || 0, r);
                        }
                      }
                      return new Date(e);
                    })(t)),
                      (this.$x = t.x || {}),
                      this.init();
                  }),
                  (p.init = function () {
                    var t = this.$d;
                    (this.$y = t.getFullYear()), (this.$M = t.getMonth()), (this.$D = t.getDate()), (this.$W = t.getDay()), (this.$H = t.getHours()), (this.$m = t.getMinutes()), (this.$s = t.getSeconds()), (this.$ms = t.getMilliseconds());
                  }),
                  (p.$utils = function () {
                    return k;
                  }),
                  (p.isValid = function () {
                    return !(this.$d.toString() === h);
                  }),
                  (p.isSame = function (t, e) {
                    var n = T(t);
                    return this.startOf(e) <= n && n <= this.endOf(e);
                  }),
                  (p.isAfter = function (t, e) {
                    return T(t) < this.startOf(e);
                  }),
                  (p.isBefore = function (t, e) {
                    return this.endOf(e) < T(t);
                  }),
                  (p.$g = function (t, e, n) {
                    return k.u(t) ? this[e] : this.set(n, t);
                  }),
                  (p.unix = function () {
                    return Math.floor(this.valueOf() / 1e3);
                  }),
                  (p.valueOf = function () {
                    return this.$d.getTime();
                  }),
                  (p.startOf = function (t, e) {
                    var n = this,
                      l = !!k.u(e) || e,
                      h = k.p(t),
                      m = function (t, e) {
                        var i = k.w(n.$u ? Date.UTC(n.$y, e, t) : new Date(n.$y, e, t), n);
                        return l ? i : i.endOf(o);
                      },
                      y = function (t, e) {
                        return k.w(n.toDate()[t].apply(n.toDate("s"), (l ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(e)), n);
                      },
                      f = this.$W,
                      p = this.$M,
                      v = this.$D,
                      g = "set" + (this.$u ? "UTC" : "");
                    switch (h) {
                      case u:
                        return l ? m(1, 0) : m(31, 11);
                      case c:
                        return l ? m(1, p) : m(0, p + 1);
                      case s:
                        var b = this.$locale().weekStart || 0,
                          w = (f < b ? f + 7 : f) - b;
                        return m(l ? v - w : v + (6 - w), p);
                      case o:
                      case d:
                        return y(g + "Hours", 0);
                      case r:
                        return y(g + "Minutes", 1);
                      case a:
                        return y(g + "Seconds", 2);
                      case i:
                        return y(g + "Milliseconds", 3);
                      default:
                        return this.clone();
                    }
                  }),
                  (p.endOf = function (t) {
                    return this.startOf(t, !1);
                  }),
                  (p.$set = function (t, e) {
                    var s,
                      l = k.p(t),
                      h = "set" + (this.$u ? "UTC" : ""),
                      m = ((s = {}), (s[o] = h + "Date"), (s[d] = h + "Date"), (s[c] = h + "Month"), (s[u] = h + "FullYear"), (s[r] = h + "Hours"), (s[a] = h + "Minutes"), (s[i] = h + "Seconds"), (s[n] = h + "Milliseconds"), s)[l],
                      y = l === o ? this.$D + (e - this.$W) : e;
                    if (l === c || l === u) {
                      var f = this.clone().set(d, 1);
                      f.$d[m](y), f.init(), (this.$d = f.set(d, Math.min(this.$D, f.daysInMonth())).$d);
                    } else m && this.$d[m](y);
                    return this.init(), this;
                  }),
                  (p.set = function (t, e) {
                    return this.clone().$set(t, e);
                  }),
                  (p.get = function (t) {
                    return this[k.p(t)]();
                  }),
                  (p.add = function (n, l) {
                    var d,
                      h = this;
                    n = Number(n);
                    var m = k.p(l),
                      y = function (t) {
                        var e = T(h);
                        return k.w(e.date(e.date() + Math.round(t * n)), h);
                      };
                    if (m === c) return this.set(c, this.$M + n);
                    if (m === u) return this.set(u, this.$y + n);
                    if (m === o) return y(1);
                    if (m === s) return y(7);
                    var f = ((d = {}), (d[a] = t), (d[r] = e), (d[i] = 1e3), d)[m] || 1,
                      p = this.$d.getTime() + n * f;
                    return k.w(p, this);
                  }),
                  (p.subtract = function (t, e) {
                    return this.add(-1 * t, e);
                  }),
                  (p.format = function (t) {
                    var e = this,
                      n = this.$locale();
                    if (!this.isValid()) return n.invalidDate || h;
                    var i = t || "YYYY-MM-DDTHH:mm:ssZ",
                      a = k.z(this),
                      r = this.$H,
                      o = this.$m,
                      s = this.$M,
                      c = n.weekdays,
                      l = n.months,
                      u = n.meridiem,
                      d = function (t, n, a, r) {
                        return (t && (t[n] || t(e, i))) || a[n].slice(0, r);
                      },
                      m = function (t) {
                        return k.s(r % 12 || 12, t, "0");
                      },
                      f =
                        u ||
                        function (t, e, n) {
                          var i = t < 12 ? "AM" : "PM";
                          return n ? i.toLowerCase() : i;
                        };
                    return i.replace(y, function (t, i) {
                      return (
                        i ||
                        (function (t) {
                          switch (t) {
                            case "YY":
                              return String(e.$y).slice(-2);
                            case "YYYY":
                              return k.s(e.$y, 4, "0");
                            case "M":
                              return s + 1;
                            case "MM":
                              return k.s(s + 1, 2, "0");
                            case "MMM":
                              return d(n.monthsShort, s, l, 3);
                            case "MMMM":
                              return d(l, s);
                            case "D":
                              return e.$D;
                            case "DD":
                              return k.s(e.$D, 2, "0");
                            case "d":
                              return String(e.$W);
                            case "dd":
                              return d(n.weekdaysMin, e.$W, c, 2);
                            case "ddd":
                              return d(n.weekdaysShort, e.$W, c, 3);
                            case "dddd":
                              return c[e.$W];
                            case "H":
                              return String(r);
                            case "HH":
                              return k.s(r, 2, "0");
                            case "h":
                              return m(1);
                            case "hh":
                              return m(2);
                            case "a":
                              return f(r, o, !0);
                            case "A":
                              return f(r, o, !1);
                            case "m":
                              return String(o);
                            case "mm":
                              return k.s(o, 2, "0");
                            case "s":
                              return String(e.$s);
                            case "ss":
                              return k.s(e.$s, 2, "0");
                            case "SSS":
                              return k.s(e.$ms, 3, "0");
                            case "Z":
                              return a;
                          }
                          return null;
                        })(t) ||
                        a.replace(":", "")
                      );
                    });
                  }),
                  (p.utcOffset = function () {
                    return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
                  }),
                  (p.diff = function (n, d, h) {
                    var m,
                      y = this,
                      f = k.p(d),
                      p = T(n),
                      v = (p.utcOffset() - this.utcOffset()) * t,
                      g = this - p,
                      b = function () {
                        return k.m(y, p);
                      };
                    switch (f) {
                      case u:
                        m = b() / 12;
                        break;
                      case c:
                        m = b();
                        break;
                      case l:
                        m = b() / 3;
                        break;
                      case s:
                        m = (g - v) / 6048e5;
                        break;
                      case o:
                        m = (g - v) / 864e5;
                        break;
                      case r:
                        m = g / e;
                        break;
                      case a:
                        m = g / t;
                        break;
                      case i:
                        m = g / 1e3;
                        break;
                      default:
                        m = g;
                    }
                    return h ? m : k.a(m);
                  }),
                  (p.daysInMonth = function () {
                    return this.endOf(c).$D;
                  }),
                  (p.$locale = function () {
                    return b[this.$L];
                  }),
                  (p.locale = function (t, e) {
                    if (!t) return this.$L;
                    var n = this.clone(),
                      i = _(t, e, !0);
                    return i && (n.$L = i), n;
                  }),
                  (p.clone = function () {
                    return k.w(this.$d, this);
                  }),
                  (p.toDate = function () {
                    return new Date(this.valueOf());
                  }),
                  (p.toJSON = function () {
                    return this.isValid() ? this.toISOString() : null;
                  }),
                  (p.toISOString = function () {
                    return this.$d.toISOString();
                  }),
                  (p.toString = function () {
                    return this.$d.toUTCString();
                  }),
                  f
                );
              })(),
              L = E.prototype;
            return (
              (T.prototype = L),
              [
                ["$ms", n],
                ["$s", i],
                ["$m", a],
                ["$H", r],
                ["$W", o],
                ["$M", c],
                ["$y", u],
                ["$D", d],
              ].forEach(function (t) {
                L[t[1]] = function (e) {
                  return this.$g(e, t[0], t[1]);
                };
              }),
              (T.extend = function (t, e) {
                return t.$i || (t(e, E, T), (t.$i = !0)), T;
              }),
              (T.locale = _),
              (T.isDayjs = w),
              (T.unix = function (t) {
                return T(1e3 * t);
              }),
              (T.en = b[g]),
              (T.Ls = b),
              (T.p = {}),
              T
            );
          })();
        },
      },
      e = {};

    function n(i) {
      var a = e[i];
      if (void 0 !== a) return a.exports;
      var r = (e[i] = { exports: {} });
      return t[i].call(r.exports, r, r.exports, n), r.exports;
    }

    (n.n = (t) => {
      var e = t && t.__esModule ? () => t.default : () => t;
      return n.d(e, { a: e }), e;
    }),
      (n.d = (t, e) => {
        for (var i in e) n.o(e, i) && !n.o(t, i) && Object.defineProperty(t, i, { enumerable: !0, get: e[i] });
      }),
      (n.o = (t, e) => Object.prototype.hasOwnProperty.call(t, e)),
      (n.r = (t) => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(t, "__esModule", { value: !0 });
      });
    var i = {};
    return (
      (() => {
        "use strict";
        n.r(i), n.d(i, { default: () => b });
        var t = n(484),
          e = n.n(t);

        function a(t) {
          var e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
            n = document.getElementById("imou_player_icon_root_".concat(t)),
            i = document.getElementById("imou_player_timeLine-web_".concat(t)),
            a = document.getElementById("imou_player_timeLine-celeContainer_".concat(t));
          e ? ((n.style.visibility = "hidden"), i && ((i.style.display = "none"), (a.style.display = "none"))) : ((n.style.visibility = ""), i && ((i.style.display = ""), (a.style.display = "")));
        }

        var r = n(132),
          o = n.n(r);

        function s(t, e, n, i, a, r, o) {
          t.beginPath(), t.moveTo(e, n), t.lineTo(i, a), (t.strokeStyle = r), (t.lineWidth = o), t.closePath(), t.stroke();
        }

        function c(t, e, n, i, a, r) {
          (t.fillStyle = r), t.fillRect(e, n, i, a);
        }

        function l(t) {
          return (
            (l =
              "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
                ? function (t) {
                    return typeof t;
                  }
                : function (t) {
                    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
                  }),
            l(t)
          );
        }

        function u(t, e) {
          for (var n = 0; n < e.length; n++) {
            var i = e[n];
            (i.enumerable = i.enumerable || !1),
              (i.configurable = !0),
              "value" in i && (i.writable = !0),
              Object.defineProperty(
                t,
                (void 0,
                (a = (function (t, e) {
                  if ("object" !== l(t) || null === t) return t;
                  var n = t[Symbol.toPrimitive];
                  if (void 0 !== n) {
                    var i = n.call(t, "string");
                    if ("object" !== l(i)) return i;
                    throw new TypeError("@@toPrimitive must return a primitive value.");
                  }
                  return String(t);
                })(i.key)),
                "symbol" === l(a) ? a : String(a)),
                i
              );
          }
          var a;
        }

        function d(t) {
          var e = Math.floor(t / 3600),
            n = Math.floor((t - 3600 * e) / 60),
            i = t - 3600 * e - 60 * n;
          return ""
            .concat(e > 9 ? e : "0".concat(e), ":")
            .concat(n > 9 ? n : "0".concat(n), ":")
            .concat(i > 9 ? i : "0".concat(i));
        }

        var h = (function () {
          function t(e) {
            var n = this,
              i = e.elem,
              a = e.width,
              r = e.beginTime,
              o = e.height,
              s = e.Obj;
            !(function (t, e) {
              if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
            })(this, t),
              (this.elem = i),
              (this.ctx = i.getContext("2d")),
              (this.width = a),
              (this.height = o),
              (i.width = a),
              (i.height = o),
              (this.record = []),
              (this.beginTime = r),
              (this.timestamp = r || 0),
              (this.elem.onmousedown = function (t) {
                n.onmousedown(t);
              }),
              (this.elem.ondragstart = function () {
                return !1;
              }),
              (this.Obj = s),
              (this.step = 10);
          }

          var e, n;
          return (
            (e = t),
            (n = [
              {
                key: "onmousedown",
                value: function (t) {
                  var e = this;
                  this.Obj.emitMessage({ type: "stopRecord" }),
                    this.stop(!1),
                    (this.startMoveX = t.offsetX),
                    (document.onmousemove = function (t) {
                      e.onmouseMove(t);
                    }),
                    (document.onmouseup = function (t) {
                      e.onmouseUp(t);
                    }),
                    (document.onmouseleave = function (t) {
                      e.onmouseUp(t);
                    });
                },
              },
              {
                key: "onmouseMove",
                value: function (t) {
                  var e = this.startMoveX - t.offsetX;
                  (this.moveTimestamp = Math.floor(this.timestamp + e)), this.moveTimestamp < 0 && (this.moveTimestamp = 0), this.moveTimestamp > 86399 && (this.moveTimestamp = 86399), this.reDrawLine(this.width / 2 - this.moveTimestamp * this.step, this.record);
                },
              },
              {
                key: "onmouseUp",
                value: function () {
                  (document.onmousemove = null), (document.onmouseup = null), (document.onmouseleave = null), (this.timestamp = this.moveTimestamp), this.Obj.changeImouUrl("beginTime", this.timestamp);
                },
              },
              {
                key: "start",
                value: function () {
                  this.loopLine();
                },
              },
              {
                key: "loopLine",
                value: function () {
                  var t = this;
                  this.reDrawLine(this.width / 2 - this.timestamp * this.step, this.record),
                    (this.AnimationFrameId = setTimeout(function () {
                      t.loopLine();
                    }, 200));
                },
              },
              {
                key: "stop",
                value: function () {
                  var t = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
                  this.AnimationFrameId && clearTimeout(this.AnimationFrameId), this.timestampTimer && clearTimeout(this.timestampTimer), (this.AnimationFrameId = null), (this.timestampTimer = null), t && ((this.timestamp = 0), (this.record = []), this.reDrawLine(this.width / 2, []));
                },
              },
              {
                key: "changeProp",
                value: function (t, e) {
                  if ("timestamp" === t) {
                    var n = this.record,
                      i = this.timestamp,
                      a = this.step;
                    if (e < 0) return void (this.timestamp = 0);
                    if (!this.AnimationFrameId) return;
                    n.length > 0 && i + 2 >= n[n.length - 1].endTime && ((this.Obj.status.message = "播放结束"), this.Obj.stopRecord(), this.Obj.stop(), this.stop(), this.reDrawLine(this.width / 2 - n[n.length - 1].endTime * a, n));
                  }
                  (this[t] = e), "width" === t && (this.elem.width = e);
                },
              },
              {
                key: "reDrawLine",
                value: function (t) {
                  var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : [],
                    n = this.ctx,
                    i = this.step;
                  n.clearRect(0, 0, this.width, this.height);
                  for (var a = 0; a < e.length; a++) {
                    var r = e[a],
                      o = t + r.beginTime * i,
                      l = t + r.endTime * i;
                    if (!(l < 0)) {
                      if (o > this.width) break;
                      (o > 0 || l > 0 || o < this.width || l < this.width) && c(n, t + r.beginTime * i, 0, (r.endTime - r.beginTime) * i, 50, "#384D65");
                    }
                  }
                  for (var u = 0; u < 86400; u += 1) {
                    var h = t + u * i;
                    if (!(h < 0)) {
                      if (h > this.width) break;
                      u % 10 == 0 ? (s(n, h, 0, h, 50, "#808080", 2), (n.font = "15px PingFang SC"), (n.fillStyle = "#D8D8D8"), n.fillText(d(u), h + 12, 20)) : s(n, h, 30, h, 50, "#808080", 2);
                    }
                  }
                  s(n, this.width / 2, 0, this.width / 2, 50, "#f18d00", 2);
                },
              },
            ]),
            n && u(e.prototype, n),
            Object.defineProperty(e, "prototype", { writable: !1 }),
            t
          );
        })();

        function m(t) {
          return (
            (m =
              "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
                ? function (t) {
                    return typeof t;
                  }
                : function (t) {
                    return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
                  }),
            m(t)
          );
        }

        function y() {
          y = function () {
            return t;
          };
          var t = {},
            e = Object.prototype,
            n = e.hasOwnProperty,
            i =
              Object.defineProperty ||
              function (t, e, n) {
                t[e] = n.value;
              },
            a = "function" == typeof Symbol ? Symbol : {},
            r = a.iterator || "@@iterator",
            o = a.asyncIterator || "@@asyncIterator",
            s = a.toStringTag || "@@toStringTag";

          function c(t, e, n) {
            return Object.defineProperty(t, e, { value: n, enumerable: !0, configurable: !0, writable: !0 }), t[e];
          }

          try {
            c({}, "");
          } catch (t) {
            c = function (t, e, n) {
              return (t[e] = n);
            };
          }

          function l(t, e, n, a) {
            var r = e && e.prototype instanceof h ? e : h,
              o = Object.create(r.prototype),
              s = new D(a || []);
            return i(o, "_invoke", { value: k(t, n, s) }), o;
          }

          function u(t, e, n) {
            try {
              return { type: "normal", arg: t.call(e, n) };
            } catch (t) {
              return { type: "throw", arg: t };
            }
          }

          t.wrap = l;
          var d = {};

          function h() {}

          function f() {}

          function p() {}

          var v = {};
          c(v, r, function () {
            return this;
          });
          var g = Object.getPrototypeOf,
            b = g && g(g(S([])));
          b && b !== e && n.call(b, r) && (v = b);
          var w = (p.prototype = h.prototype = Object.create(v));

          function _(t) {
            ["next", "throw", "return"].forEach(function (e) {
              c(t, e, function (t) {
                return this._invoke(e, t);
              });
            });
          }

          function T(t, e) {
            function a(i, r, o, s) {
              var c = u(t[i], t, r);
              if ("throw" !== c.type) {
                var l = c.arg,
                  d = l.value;
                return d && "object" == m(d) && n.call(d, "__await")
                  ? e.resolve(d.__await).then(
                      function (t) {
                        a("next", t, o, s);
                      },
                      function (t) {
                        a("throw", t, o, s);
                      }
                    )
                  : e.resolve(d).then(
                      function (t) {
                        (l.value = t), o(l);
                      },
                      function (t) {
                        return a("throw", t, o, s);
                      }
                    );
              }
              s(c.arg);
            }

            var r;
            i(this, "_invoke", {
              value: function (t, n) {
                function i() {
                  return new e(function (e, i) {
                    a(t, n, e, i);
                  });
                }

                return (r = r ? r.then(i, i) : i());
              },
            });
          }

          function k(t, e, n) {
            var i = "suspendedStart";
            return function (a, r) {
              if ("executing" === i) throw new Error("Generator is already running");
              if ("completed" === i) {
                if ("throw" === a) throw r;
                return { value: void 0, done: !0 };
              }
              for (n.method = a, n.arg = r; ; ) {
                var o = n.delegate;
                if (o) {
                  var s = E(o, n);
                  if (s) {
                    if (s === d) continue;
                    return s;
                  }
                }
                if ("next" === n.method) n.sent = n._sent = n.arg;
                else if ("throw" === n.method) {
                  if ("suspendedStart" === i) throw ((i = "completed"), n.arg);
                  n.dispatchException(n.arg);
                } else "return" === n.method && n.abrupt("return", n.arg);
                i = "executing";
                var c = u(t, e, n);
                if ("normal" === c.type) {
                  if (((i = n.done ? "completed" : "suspendedYield"), c.arg === d)) continue;
                  return { value: c.arg, done: n.done };
                }
                "throw" === c.type && ((i = "completed"), (n.method = "throw"), (n.arg = c.arg));
              }
            };
          }

          function E(t, e) {
            var n = e.method,
              i = t.iterator[n];
            if (void 0 === i) return (e.delegate = null), ("throw" === n && t.iterator.return && ((e.method = "return"), (e.arg = void 0), E(t, e), "throw" === e.method)) || ("return" !== n && ((e.method = "throw"), (e.arg = new TypeError("The iterator does not provide a '" + n + "' method")))), d;
            var a = u(i, t.iterator, e.arg);
            if ("throw" === a.type) return (e.method = "throw"), (e.arg = a.arg), (e.delegate = null), d;
            var r = a.arg;
            return r ? (r.done ? ((e[t.resultName] = r.value), (e.next = t.nextLoc), "return" !== e.method && ((e.method = "next"), (e.arg = void 0)), (e.delegate = null), d) : r) : ((e.method = "throw"), (e.arg = new TypeError("iterator result is not an object")), (e.delegate = null), d);
          }

          function L(t) {
            var e = { tryLoc: t[0] };
            1 in t && (e.catchLoc = t[1]), 2 in t && ((e.finallyLoc = t[2]), (e.afterLoc = t[3])), this.tryEntries.push(e);
          }

          function x(t) {
            var e = t.completion || {};
            (e.type = "normal"), delete e.arg, (t.completion = e);
          }

          function D(t) {
            (this.tryEntries = [{ tryLoc: "root" }]), t.forEach(L, this), this.reset(!0);
          }

          function S(t) {
            if (t || "" === t) {
              var e = t[r];
              if (e) return e.call(t);
              if ("function" == typeof t.next) return t;
              if (!isNaN(t.length)) {
                var i = -1,
                  a = function e() {
                    for (; ++i < t.length; ) if (n.call(t, i)) return (e.value = t[i]), (e.done = !1), e;
                    return (e.value = void 0), (e.done = !0), e;
                  };
                return (a.next = a);
              }
            }
            throw new TypeError(m(t) + " is not iterable");
          }

          return (
            (f.prototype = p),
            i(w, "constructor", { value: p, configurable: !0 }),
            i(p, "constructor", {
              value: f,
              configurable: !0,
            }),
            (f.displayName = c(p, s, "GeneratorFunction")),
            (t.isGeneratorFunction = function (t) {
              var e = "function" == typeof t && t.constructor;
              return !!e && (e === f || "GeneratorFunction" === (e.displayName || e.name));
            }),
            (t.mark = function (t) {
              return Object.setPrototypeOf ? Object.setPrototypeOf(t, p) : ((t.__proto__ = p), c(t, s, "GeneratorFunction")), (t.prototype = Object.create(w)), t;
            }),
            (t.awrap = function (t) {
              return { __await: t };
            }),
            _(T.prototype),
            c(T.prototype, o, function () {
              return this;
            }),
            (t.AsyncIterator = T),
            (t.async = function (e, n, i, a, r) {
              void 0 === r && (r = Promise);
              var o = new T(l(e, n, i, a), r);
              return t.isGeneratorFunction(n)
                ? o
                : o.next().then(function (t) {
                    return t.done ? t.value : o.next();
                  });
            }),
            _(w),
            c(w, s, "Generator"),
            c(w, r, function () {
              return this;
            }),
            c(w, "toString", function () {
              return "[object Generator]";
            }),
            (t.keys = function (t) {
              var e = Object(t),
                n = [];
              for (var i in e) n.push(i);
              return (
                n.reverse(),
                function t() {
                  for (; n.length; ) {
                    var i = n.pop();
                    if (i in e) return (t.value = i), (t.done = !1), t;
                  }
                  return (t.done = !0), t;
                }
              );
            }),
            (t.values = S),
            (D.prototype = {
              constructor: D,
              reset: function (t) {
                if (((this.prev = 0), (this.next = 0), (this.sent = this._sent = void 0), (this.done = !1), (this.delegate = null), (this.method = "next"), (this.arg = void 0), this.tryEntries.forEach(x), !t)) for (var e in this) "t" === e.charAt(0) && n.call(this, e) && !isNaN(+e.slice(1)) && (this[e] = void 0);
              },
              stop: function () {
                this.done = !0;
                var t = this.tryEntries[0].completion;
                if ("throw" === t.type) throw t.arg;
                return this.rval;
              },
              dispatchException: function (t) {
                if (this.done) throw t;
                var e = this;

                function i(n, i) {
                  return (o.type = "throw"), (o.arg = t), (e.next = n), i && ((e.method = "next"), (e.arg = void 0)), !!i;
                }

                for (var a = this.tryEntries.length - 1; a >= 0; --a) {
                  var r = this.tryEntries[a],
                    o = r.completion;
                  if ("root" === r.tryLoc) return i("end");
                  if (r.tryLoc <= this.prev) {
                    var s = n.call(r, "catchLoc"),
                      c = n.call(r, "finallyLoc");
                    if (s && c) {
                      if (this.prev < r.catchLoc) return i(r.catchLoc, !0);
                      if (this.prev < r.finallyLoc) return i(r.finallyLoc);
                    } else if (s) {
                      if (this.prev < r.catchLoc) return i(r.catchLoc, !0);
                    } else {
                      if (!c) throw new Error("try statement without catch or finally");
                      if (this.prev < r.finallyLoc) return i(r.finallyLoc);
                    }
                  }
                }
              },
              abrupt: function (t, e) {
                for (var i = this.tryEntries.length - 1; i >= 0; --i) {
                  var a = this.tryEntries[i];
                  if (a.tryLoc <= this.prev && n.call(a, "finallyLoc") && this.prev < a.finallyLoc) {
                    var r = a;
                    break;
                  }
                }
                r && ("break" === t || "continue" === t) && r.tryLoc <= e && e <= r.finallyLoc && (r = null);
                var o = r ? r.completion : {};
                return (o.type = t), (o.arg = e), r ? ((this.method = "next"), (this.next = r.finallyLoc), d) : this.complete(o);
              },
              complete: function (t, e) {
                if ("throw" === t.type) throw t.arg;
                return "break" === t.type || "continue" === t.type ? (this.next = t.arg) : "return" === t.type ? ((this.rval = this.arg = t.arg), (this.method = "return"), (this.next = "end")) : "normal" === t.type && e && (this.next = e), d;
              },
              finish: function (t) {
                for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                  var n = this.tryEntries[e];
                  if (n.finallyLoc === t) return this.complete(n.completion, n.afterLoc), x(n), d;
                }
              },
              catch: function (t) {
                for (var e = this.tryEntries.length - 1; e >= 0; --e) {
                  var n = this.tryEntries[e];
                  if (n.tryLoc === t) {
                    var i = n.completion;
                    if ("throw" === i.type) {
                      var a = i.arg;
                      x(n);
                    }
                    return a;
                  }
                }
                throw new Error("illegal catch attempt");
              },
              delegateYield: function (t, e, n) {
                return (
                  (this.delegate = {
                    iterator: S(t),
                    resultName: e,
                    nextLoc: n,
                  }),
                  "next" === this.method && (this.arg = void 0),
                  d
                );
              },
            }),
            t
          );
        }

        function f(t, e, n, i, a, r, o) {
          try {
            var s = t[r](o),
              c = s.value;
          } catch (t) {
            return void n(t);
          }
          s.done ? e(c) : Promise.resolve(c).then(i, a);
        }

        function p(t, e) {
          (null == e || e > t.length) && (e = t.length);
          for (var n = 0, i = new Array(e); n < e; n++) i[n] = t[n];
          return i;
        }

        function v(t, e) {
          for (var n = 0; n < e.length; n++) {
            var i = e[n];
            (i.enumerable = i.enumerable || !1),
              (i.configurable = !0),
              "value" in i && (i.writable = !0),
              Object.defineProperty(
                t,
                (void 0,
                (a = (function (t, e) {
                  if ("object" !== m(t) || null === t) return t;
                  var n = t[Symbol.toPrimitive];
                  if (void 0 !== n) {
                    var i = n.call(t, "string");
                    if ("object" !== m(i)) return i;
                    throw new TypeError("@@toPrimitive must return a primitive value.");
                  }
                  return String(t);
                })(i.key)),
                "symbol" === m(a) ? a : String(a)),
                i
              );
          }
          var a;
        }

        var g = (function () {
          function t(n) {
            var i = this;
            !(function (t, e) {
              if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
            })(this, t);
            var a = n.id,
              r = n.token,
              s = n.deviceId,
              c = n.channelId,
              l = n.width,
              u = n.height,
              d = n.code,
              m = void 0 === d ? "" : d,
              y = n.mute,
              f = void 0 !== y && y,
              v = n.beginTime || e()().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
              g = n.endTime || e()(v).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
              b = {
                id: a,
                token: r,
                deviceId: s,
                channelId: c,
                code: m || s,
                width: l,
                height: u,
                playing: !1,
                volume: !0,
                type: parseInt(n.type) || 1,
                clarity: n.streamId || 0,
                fullPage: !1,
                fullWebPage: !1,
                talk: !1,
                yuntai: !1,
                message: "",
                recordType: n.recordType || "cloud",
                beginTime: v,
                endTime: g,
                obj: this,
                videoRecord: !1,
              };
            this.height = u;
            var w = "imou://open.lechange.com/".concat(b.deviceId, "/").concat(b.channelId, "/").concat(b.type, "?streamId=").concat(b.clarity, "&recordType=").concat(b.recordType, "&beginTime=").concat(b.beginTime, "&endTime=").concat(b.endTime);
            (this.url = w),
              (this.token = r),
              (this.iframeId = "imou-player-iframe-".concat(Date.now())),
              (this.root = document.getElementById(a)),
              (this.root.style.backgroundColor = "black"),
              (this.root.style.position = "relative"),
              (this.root.style.width = "".concat(b.width, "px")),
              (this.root.style.height = "".concat(b.height, "px")),
              (this.root.style.userSelect = "none"),
              (this.domain = "https://resource-public.oss-cn-hangzhou.aliyuncs.com/webFront/openweb/mini-player/index.html"),
              (this.record = []),
              (this.status = (function (t) {
                return new Proxy(t, {
                  set: function (e, n, i) {
                    var a,
                      r = t.obj.iframeId;
                    try {
                      switch (n) {
                        case "playing":
                          (a = document.getElementById("imou_player_icon_play_".concat(r))), i ? (a.classList.remove("icon-play"), a.classList.add("icon-play_1")) : (a.classList.add("icon-play"), a.classList.remove("icon-play_1"));
                          break;
                        case "volume":
                          (a = document.getElementById("imou_player_icon_voice_".concat(r))), i ? (a.classList.remove("icon-voice_1"), a.classList.add("icon-voice")) : (a.classList.remove("icon-voice"), a.classList.add("icon-voice_1"));
                          break;
                        case "talk":
                          (a = document.getElementById("imou_player_icon_talk_".concat(r))), i ? (a.classList.remove("icon-talk"), a.classList.add("icon-talk_1")) : (a.classList.remove("icon-talk_1"), a.classList.add("icon-talk"));
                          break;
                        case "clarity":
                          if (!(a = document.getElementById("imou_player_icon_clarity_".concat(r)))) break;
                          i ? (a.classList.remove("icon-clarity"), a.classList.add("icon-clarity_1")) : (a.classList.remove("icon-clarity_1"), a.classList.add("icon-clarity"));
                          break;
                        case "videoRecord":
                          (a = document.getElementById("imou_player_icon_videoRecord_".concat(r))), i ? (a.classList.remove("icon-videoRecord"), a.classList.add("icon-videoRecord_1")) : (a.classList.remove("icon-videoRecord_1"), a.classList.add("icon-videoRecord"));
                          break;
                        case "message":
                          !(function (t, e) {
                            var n = document.getElementById("imou_player_message_root_".concat(t)),
                              i = document.getElementById("imou_player_icon_root_".concat(t)),
                              a = document.getElementById("imou_player_timeLine-web_".concat(t)),
                              r = document.getElementById("imou_player_timeLine-celeContainer_".concat(t));
                            e ? ((n.innerText = e), (n.style.display = ""), "解密失败, 请重新输入秘钥" === e && ((i.style.display = "none"), a && ((a.style.display = "none"), (r.style.display = "none")))) : ((n.style.display = "none"), (i.style.display = ""), a && ((a.style.display = ""), (r.style.display = "")));
                          })(t.obj.iframeId, i);
                          break;
                        case "yuntai":
                          (a = document.getElementById("imou_player_PTZArea_".concat(r))).style.display = i ? "" : "none";
                          break;
                        case "recordType":
                          "cloud" === i ? (((a = document.getElementById("imou_player_icon_YunLuXiang_".concat(r))).style.color = "blue"), ((a = document.getElementById("imou_player_icon_BenDiLuXiang_".concat(r))).style.color = "")) : (((a = document.getElementById("imou_player_icon_YunLuXiang_".concat(r))).style.color = ""), ((a = document.getElementById("imou_player_icon_BenDiLuXiang_".concat(r))).style.color = "blue"));
                          break;
                        case "fullPage":
                          i ? t.obj.fullScreen() : t.obj.exitFullScreen();
                          break;
                        case "fullWebPage":
                          if (t.fullPage) return !0;
                          i ? t.obj.fullPageScreen() : t.obj.exitFullPageScreen();
                      }
                    } catch (t) {}
                    return (e[n] = i), !0;
                  },
                });
              })(b));
            var _,
              T =
                1 === (_ = this).status.type
                  ? (function (t) {
                      var e = new DocumentFragment(),
                        n = document.createElement("div");
                      n.setAttribute("id", "imou_player_icon_root_".concat(t.iframeId)), (n.style.display = "none"), (n.style.position = "absolute"), (n.style.width = "100%"), (n.style.height = "100%"), (n.style.zIndex = "99999");
                      var i = document.createElement("div");
                      (i.style.color = "#f18d00"),
                        (i.innerHTML = "".concat(t.status.deviceId, " 通道 ").concat(t.status.channelId)),
                        i.setAttribute("class", "topLeft"),
                        n.appendChild(i),
                        ["play", "voice", "talk", "capture", "videoRecord"].forEach(function (e, i) {
                          var a = document.createElement("div");
                          a.setAttribute("id", "imou_player_icon_".concat(e, "_").concat(t.iframeId)), a.setAttribute("class", "iconfont icon-".concat(e, " icon position_left_").concat(i)), n.appendChild(a);
                        }),
                        ["fullPage", "WangYeQuanPing", "clarity", "Icon_YunTai"].forEach(function (e, i) {
                          var a = document.createElement("div");
                          if ((a.setAttribute("id", "imou_player_icon_".concat(e, "_").concat(t.iframeId)), a.setAttribute("class", "iconfont icon-".concat(e, " icon position_right_").concat(i)), "clarity" === e)) {
                            var r = "0" == t.status.clarity ? "" : "_1";
                            a.setAttribute("class", "iconfont icon-".concat(e).concat(r, " icon position_right_").concat(i));
                          }
                          n.appendChild(a);
                        }),
                        e.appendChild(n),
                        n.addEventListener("click", function (e) {
                          switch (e.target.id) {
                            case "imou_player_icon_play_".concat(t.iframeId):
                              t.status.playing ? t.pause() : t.play();
                              break;
                            case "imou_player_icon_capture_".concat(t.iframeId):
                              t.capture();
                              break;
                            case "imou_player_icon_talk_".concat(t.iframeId):
                              t.talk(!t.status.talk);
                              break;
                            case "imou_player_icon_voice_".concat(t.iframeId):
                              t.volume(!t.status.volume);
                              break;
                            case "imou_player_icon_videoRecord_".concat(t.iframeId):
                              t.status.videoRecord ? t.stopRecord() : t.startRecord();
                              break;
                            case "imou_player_icon_fullPage_".concat(t.iframeId):
                              t.status.fullPage = !t.status.fullPage;
                              break;
                            case "imou_player_icon_WangYeQuanPing_".concat(t.iframeId):
                              t.status.fullWebPage = !t.status.fullWebPage;
                              break;
                            case "imou_player_icon_clarity_".concat(t.iframeId):
                              t.stopRecord(),
                                (t.status.message = "切换码流中..."),
                                setTimeout(function () {
                                  t.changeImouUrl("clarity", "0" == t.status.clarity ? 1 : 0);
                                }, 0);
                              break;
                            case "imou_player_icon_Icon_YunTai_".concat(t.iframeId):
                              t.status.yuntai = !t.status.yuntai;
                          }
                        });
                      var a = document.createElement("div");
                      a.setAttribute("id", "imou_player_message_root_".concat(t.iframeId)), a.setAttribute("style", "position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:1.2em;color:white;z-index:99999;"), (a.style.display = "none"), e.appendChild(a);
                      var r = document.createElement("div");
                      return (
                        (r.style.display = "none"),
                        r.setAttribute("id", "imou_player_PTZArea_".concat(t.iframeId)),
                        r.setAttribute("class", "player__PTZArea"),
                        (r.style.zIndex = "99999"),
                        ["up", "down", "left", "right"].forEach(function (t) {
                          var e = document.createElement("div");
                          e.setAttribute("class", "arrow-".concat(t)), r.appendChild(e);
                        }),
                        r.addEventListener("mousedown", function (e) {
                          var n = e.offsetX,
                            i = e.offsetY,
                            a = "";
                          n > 40 ? (n > 80 ? i > 40 && i < 80 && (a = "right") : i < 40 ? (a = "up") : i > 80 && (a = "down")) : i > 40 && i < 80 && (a = "left"), a && t.ptzControl(a);
                        }),
                        r.addEventListener("mouseup", function () {
                          t.ptzControl("stop");
                        }),
                        e.appendChild(r),
                        e
                      );
                    })(_)
                  : (function (t) {
                      var n = new DocumentFragment(),
                        i = t.iframeId,
                        a = document.createElement("div");
                      a.setAttribute("id", "imou_player_icon_root_".concat(i)), (a.style.display = "none"), (a.style.position = "absolute"), (a.style.width = "100%"), (a.style.height = "100%"), (a.style.zIndex = "99999");
                      var r = document.createElement("div");
                      (r.style.color = "#f18d00"),
                        (r.innerHTML = "".concat(t.status.deviceId, " 通道 ").concat(t.status.channelId)),
                        r.setAttribute("class", "topLeft"),
                        a.appendChild(r),
                        ["play", "voice", "capture", "videoRecord"].forEach(function (t, e) {
                          var n = document.createElement("div");
                          n.setAttribute("id", "imou_player_icon_".concat(t, "_").concat(i)), n.setAttribute("class", "iconfont icon-".concat(t, " record-icon position_left_").concat(e)), a.appendChild(n);
                        }),
                        ["fullPage", "WangYeQuanPing"].forEach(function (t, e) {
                          var n = document.createElement("div");
                          n.setAttribute("id", "imou_player_icon_".concat(t, "_").concat(i)), n.setAttribute("class", "iconfont icon-".concat(t, " record-icon position_right_").concat(e)), a.appendChild(n);
                        }),
                        ["BenDiLuXiang", "YunLuXiang"].forEach(function (e, n) {
                          var r = document.createElement("div");
                          r.setAttribute("id", "imou_player_icon_".concat(e, "_").concat(i)), r.setAttribute("class", "iconfont icon-Icon_".concat(e, " record-icon topRight position_right_").concat(n)), "localRecord" === t.status.recordType && "BenDiLuXiang" === e && (r.style.color = "blue"), "localRecord" !== t.status.recordType && "YunLuXiang" === e && (r.style.color = "blue"), a.appendChild(r);
                        }),
                        n.appendChild(a),
                        a.addEventListener("click", function (e) {
                          switch (e.target.id) {
                            case "imou_player_icon_play_".concat(i):
                              t.status.playing ? t.pause() : t.play();
                              break;
                            case "imou_player_icon_capture_".concat(i):
                              t.capture();
                              break;
                            case "imou_player_icon_voice_".concat(i):
                              t.volume(!t.status.volume);
                              break;
                            case "imou_player_icon_fullPage_".concat(i):
                              t.status.fullPage = !t.status.fullPage;
                              break;
                            case "imou_player_icon_WangYeQuanPing_".concat(i):
                              t.status.fullWebPage = !t.status.fullWebPage;
                              break;
                            case "imou_player_icon_BenDiLuXiang_".concat(i):
                              (t.status.message = "切换录像至本地录像中..."), t.changeImouUrl("recordType", "localRecord");
                              break;
                            case "imou_player_icon_YunLuXiang_".concat(i):
                              (t.status.message = "切换录像至云录像中..."), t.changeImouUrl("recordType", "cloud");
                              break;
                            case "imou_player_icon_videoRecord_".concat(t.iframeId):
                              t.status.videoRecord ? t.stopRecord() : t.startRecord();
                          }
                        });
                      var s = document.createElement("div");
                      s.setAttribute("id", "imou_player_message_root_".concat(i)), s.setAttribute("style", "position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);font-size:1.2em;color:white;z-index:99999;"), (s.style.display = "none"), n.appendChild(s);
                      var c = document.createElement("canvas");
                      c.setAttribute("id", "imou_player_timeLine-web_".concat(i)), c.setAttribute("style", "display:none;position:absolute;bottom:0px;z-index:99999;background-color:rgba(60, 60, 60,1);");
                      var l = new h({
                        elem: c,
                        width: t.status.width,
                        height: 50,
                        Obj: t,
                        beginTime: e()(t.status.beginTime).unix() - e()(t.status.beginTime).startOf("day").unix(),
                      });
                      (t.timeLine = l), n.appendChild(c);
                      var u = document.createElement("div");
                      u.setAttribute("id", "imou_player_timeLine-celeContainer_".concat(i)), u.setAttribute("style", "display:none;position:absolute;bottom:0px;padding-right:10px;right:0px;z-index:99999;width:35px;height:50px;background-color:rgb(60, 60, 60);");
                      var d = document.createElement("div");
                      d.setAttribute("class", "iconfont icon-Console_icon_calendar record-icon"),
                        d.setAttribute("style", "bottom:10px;left:5px;"),
                        d.addEventListener("click", function () {
                          "" === m.style.display ? (m.style.display = "none") : (m.style.display = "");
                        });
                      var m = document.createElement("div");
                      return (
                        m.setAttribute("id", "player_timeLine-cele_".concat(i)),
                        m.setAttribute("style", "display:none;position:absolute;top:-332px;left:-274px;width:274px;height:322px;"),
                        setTimeout(function () {
                          var n = t.status.beginTime.split(" ")[0],
                            a = e()().format("YYYY-MM-DD");
                          o().render({
                            elem: "#player_timeLine-cele_".concat(i),
                            type: "date",
                            position: "static",
                            value: n,
                            max: a,
                            btns: [],
                            done: function (e) {
                              setTimeout(function () {
                                (m.style.display = "none"), t.changeImouUrl("beginTime x", e);
                              }, 100);
                            },
                          });
                        }, 3e3),
                        u.appendChild(d),
                        u.appendChild(m),
                        n.appendChild(u),
                        n
                      );
                    })(_);
            this.root.appendChild(T),
              f && (this.status.volume = !1),
              this.crateIframe(w, r, m),
              this.createHandleClick(["play", "pause", "stop", "capture", "ptzControl", "talk", "volume", "startRecord", "stopRecord"]),
              (this.listener = {
                play: function () {},
                stop: function () {},
                pause: function () {},
                audioChange: function () {},
                talk: function () {},
                capture: function () {},
                error: function () {},
                volume: function () {},
                startRecord: function () {},
                stopRecord: function () {},
              }),
              (this.playStatus = !1),
              (this.resizeObserver = new ResizeObserver(function (t) {
                var e,
                  n = (function (t, e) {
                    var n = ("undefined" != typeof Symbol && t[Symbol.iterator]) || t["@@iterator"];
                    if (!n) {
                      if (
                        Array.isArray(t) ||
                        (n = (function (t, e) {
                          if (t) {
                            if ("string" == typeof t) return p(t, e);
                            var n = Object.prototype.toString.call(t).slice(8, -1);
                            return "Object" === n && t.constructor && (n = t.constructor.name), "Map" === n || "Set" === n ? Array.from(t) : "Arguments" === n || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n) ? p(t, e) : void 0;
                          }
                        })(t)) ||
                        (e && t && "number" == typeof t.length)
                      ) {
                        n && (t = n);
                        var i = 0,
                          a = function () {};
                        return {
                          s: a,
                          n: function () {
                            return i >= t.length ? { done: !0 } : { done: !1, value: t[i++] };
                          },
                          e: function (t) {
                            throw t;
                          },
                          f: a,
                        };
                      }
                      throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
                    }
                    var r,
                      o = !0,
                      s = !1;
                    return {
                      s: function () {
                        n = n.call(t);
                      },
                      n: function () {
                        var t = n.next();
                        return (o = t.done), t;
                      },
                      e: function (t) {
                        (s = !0), (r = t);
                      },
                      f: function () {
                        try {
                          o || null == n.return || n.return();
                        } finally {
                          if (s) throw r;
                        }
                      },
                    };
                  })(t);
                try {
                  var a = function () {
                    var t = e.value;
                    if (t.contentRect) {
                      var n = t.contentRect,
                        a = n.width,
                        r = n.height;
                      document.documentElement.clientWidth !== a && ((i.status.width = a), (i.status.height = r)),
                        setTimeout(function () {
                          i.timeLine && i.timeLine.changeProp("width", a);
                        }, 50);
                    }
                  };
                  for (n.s(); !(e = n.n()).done; ) a();
                } catch (t) {
                  n.e(t);
                } finally {
                  n.f();
                }
              })),
              this.resizeObserver.observe(this.root);
          }

          var n, i, r, s;
          return (
            (n = t),
            (i = [
              {
                key: "crateIframe",
                value: function (t, e, n) {
                  var i = this,
                    a = "url=".concat(t, "&kitToken=").concat(e, "&code=").concat(encodeURIComponent(n)),
                    r = encodeURI("".concat(this.domain, "?").concat(a));
                  this.iframeUrl = r;
                  var o = document.createElement("iframe");
                  (o.id = this.iframeId),
                    (o.src = r),
                    (o.width = "100%"),
                    (o.height = "100%"),
                    (o.style = "border:0;position:absolute;"),
                    o.setAttribute("allow", "autoplay;microphone;fullscreen;midi;encrypted-media;"),
                    o.setAttribute("frameborder", 0),
                    this.root.appendChild(o),
                    (this.iframe = o),
                    (this.iframeId = o.id),
                    ("" !== this.status.message && "播放结束" !== this.status.message) || (this.status.message = "加载中···"),
                    (this.addMessageListen = this.addMessageListen.bind(this)),
                    window.addEventListener("message", this.addMessageListen),
                    (o.onload = function () {
                      i.emitMessage({ id: o.id, type: "init" });
                    });
                  var s = function () {
                    document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || (i.status.fullPage = !1);
                  };
                  (this.exitHandler = s), document.addEventListener("webkitfullscreenchange", s, !1), document.addEventListener("mozfullscreenchange", s, !1), document.addEventListener("fullscreenchange", s, !1), document.addEventListener("MSFullscreenChange", s, !1);
                },
              },
              {
                key: "addMessageListen",
                value: function (t) {
                  var e = this,
                    n = t.data,
                    i = n.id,
                    r = n.code,
                    o = n.msg;
                  if (i === this.iframe.id)
                    switch (r) {
                      case "6001":
                        this.timeLine && this.timeLine.changeProp("timestamp", t.data.timestamp);
                        break;
                      case "2000":
                        if (this.playing) return;
                        (this.status.message = ""),
                          (this.playing = !0),
                          (this.status.playing = !0),
                          2 === this.status.type && (this.timeLine && this.timeLine.start(), this.requestRecordList()),
                          this.listener.play(),
                          setTimeout(function () {
                            !/Mobi|Android|iPhone/i.test(navigator.userAgent) && e.status.volume && e.volume(1);
                          }, 1e3),
                          (this.mouseleaveFunc = function (t) {
                            var e = this;
                            a(this.iframeId, !1),
                              this.buttonShowOrHiddenFlag && clearTimeout(this.buttonShowOrHiddenFlag),
                              (this.buttonShowOrHiddenFlag = setTimeout(function () {
                                a(e.iframeId);
                              }, 3e3));
                          }),
                          (this.mouseleaveFunc = this.mouseleaveFunc.bind(this)),
                          this.root.addEventListener("mouseover", this.mouseleaveFunc),
                          (this.buttonShowOrHiddenFlag = setTimeout(function () {
                            a(e.iframeId);
                          }, 3e3));
                        break;
                      case "2001":
                        (this.status.playing = !0), this.listener.play && this.listener.play(t.data);
                        break;
                      case "2002":
                        (this.status.playing = !1), this.listener.pause && this.listener.pause(t.data);
                        break;
                      case "2003":
                        (this.status.volume = !0), this.listener.volume(t.data);
                        break;
                      case "4209":
                        this.listener.volume(t.data);
                        break;
                      case "2004":
                        (this.status.volume = !1), this.listener.volume(t.data);
                        break;
                      case "2005":
                        (this.status.talk = !0), this.listener.talk(t.data);
                        break;
                      case "4000":
                      case "4102":
                      case "4104":
                      case "4105":
                      case "4107":
                        a(this.iframeId, !1), (this.status.message = o), this.listener.error(t.data);
                        break;
                      case "2006":
                      case "4201":
                      case "4202":
                      case "4203":
                      case "4204":
                      case "4205":
                      case "4206":
                      case "4207":
                      case "4208":
                        (this.status.talk = !1), this.listener.talk(t.data);
                        break;
                      case "6002":
                        this.doRequestRecordList(t.data.recordList);
                        break;
                      case "8001":
                        (this.status.videoRecord = !0), this.createTimer();
                        break;
                      case "8002":
                        (this.status.videoRecord = !1), this.destoryTimer();
                    }
                },
              },
              {
                key: "emitMessage",
                value: function (t) {
                  this.iframe.contentWindow.postMessage(t, this.domain);
                },
              },
              {
                key: "on",
                value: function (t, e) {
                  this.listener[t] && (this.listener[t] = e);
                },
              },
              {
                key: "emit",
                value:
                  ((r = y().mark(function t(e, n) {
                    return y().wrap(
                      function (t) {
                        for (;;)
                          switch ((t.prev = t.next)) {
                            case 0:
                              if (this.listener[e]) {
                                t.next = 2;
                                break;
                              }
                              return t.abrupt("return");
                            case 2:
                              this.listener[e].forEach(function (t) {
                                return t();
                              });
                            case 3:
                            case "end":
                              return t.stop();
                          }
                      },
                      t,
                      this
                    );
                  })),
                  (s = function () {
                    var t = this,
                      e = arguments;
                    return new Promise(function (n, i) {
                      var a = r.apply(t, e);

                      function o(t) {
                        f(a, n, i, o, s, "next", t);
                      }

                      function s(t) {
                        f(a, n, i, o, s, "throw", t);
                      }

                      o(void 0);
                    });
                  }),
                  function (t, e) {
                    return s.apply(this, arguments);
                  }),
              },
              {
                key: "createHandleClick",
                value: function (t) {
                  var e = this;
                  t.forEach(function (t) {
                    e[t] = function (n) {
                      ("play" !== t || e.playing) &&
                        e.emitMessage({
                          id: e.iframe.id,
                          type: "".concat(t),
                          params: n,
                        });
                    };
                  });
                },
              },
              {
                key: "fullScreen",
                value: function () {
                  var t = this,
                    e = this.root;
                  (e.requestFullScreen || e.webkitRequestFullScreen || e.mozRequestFullScreen || e.msRequestFullscreen).call(e),
                    setTimeout(function () {
                      t.timeLine && t.timeLine.changeProp("width", window.screen.width);
                    }, 50);
                },
              },
              {
                key: "exitFullScreen",
                value: function () {
                  var t = this;
                  (document.exitFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || document.msExitFullscreen).call(document),
                    setTimeout(function () {
                      t.timeLine && t.timeLine.changeProp("width", t.status.fullWebPage ? document.documentElement.clientWidth : t.status.width);
                    }, 100);
                },
              },
              {
                key: "fullPageScreen",
                value: function () {
                  var t = this;
                  window.scroll(0, 0), (document.body.style.overflow = "hidden");
                  var e = document.documentElement.clientWidth,
                    n = document.documentElement.clientHeight;
                  (this.root.style.position = "absolute"),
                    (this.root.style.left = "0"),
                    (this.root.style.top = "0"),
                    (this.root.style.width = "".concat(e, "px")),
                    (this.root.style.height = "".concat(n, "px")),
                    (this.root.style.zIndex = "999999"),
                    setTimeout(function () {
                      t.timeLine && t.timeLine.changeProp("width", e);
                    }, 100);
                },
              },
              {
                key: "exitFullPageScreen",
                value: function () {
                  var t = this;
                  (this.root.style.position = "relative"),
                    (this.root.style.width = "".concat(this.status.width, "px")),
                    (this.root.style.height = "".concat(this.status.height, "px")),
                    (this.root.style.zIndex = ""),
                    (document.body.style.overflow = ""),
                    setTimeout(function () {
                      t.timeLine && t.timeLine.changeProp("width", t.status.width);
                    }, 0);
                },
              },
              {
                key: "changeImouUrl",
                value: function (t, n) {
                  switch ((console.log("======播放链接已被修改========="), (this.status.videoRecord = !1), this.destoryTimer(), this.destroy(!1), t)) {
                    case "beginTime":
                      (this.status.beginTime = e()
                        .unix(e()(this.status.beginTime).startOf("day").unix() + n)
                        .format("YYYY-MM-DD HH:mm:ss")),
                        (this.status.endTime = e()(this.status.beginTime).endOf("day").format("YYYY-MM-DD HH:mm:ss"));
                      break;
                    case "beginTime x":
                      (this.status.beginTime = e()(n).format("YYYY-MM-DD HH:mm:ss")), (this.status.endTime = e()(this.status.beginTime).endOf("day").format("YYYY-MM-DD HH:mm:ss")), (this.record = []), this.timeLine && this.timeLine.stop(!0), (this.status.message = "切换日期至".concat(n, "中..."));
                      break;
                    case "recordType":
                      (this.status.beginTime = e()(this.status.beginTime).startOf("day").format("YYYY-MM-DD HH:mm:ss")), (this.status.endTime = e()(this.status.beginTime).endOf("day").format("YYYY-MM-DD HH:mm:ss")), (this.record = []), this.timeLine && this.timeLine.stop(!0), (this.status[t] = n);
                      break;
                    default:
                      this.status[t] = n;
                  }
                  (this.status.yuntai = !1), (this.status.talk = !1), (this.status.volume = 1), (this.status.playing = !1);
                  var i = this.status,
                    a = "imou://open.lechange.com/".concat(i.deviceId, "/").concat(i.channelId, "/").concat(i.type, "?streamId=").concat(i.clarity, "&recordType=").concat(i.recordType, "&beginTime=").concat(i.beginTime, "&endTime=").concat(i.endTime);
                  this.crateIframe(a, i.token, i.code);
                },
              },
              {
                key: "destroy",
                value: function () {
                  var t = !(arguments.length > 0 && void 0 !== arguments[0]) || arguments[0],
                    e = this.iframe;
                  this.iframe.src = "about:blank";
                  try {
                    e.contentWindow.document.write(""), e.contentWindow.document.clear();
                  } catch (t) {
                    console.log(t);
                  }
                  if ((e.parentNode.removeChild(e), document.removeEventListener("webkitfullscreenchange", this.exitHandler), document.removeEventListener("mozfullscreenchange", this.exitHandler), document.removeEventListener("fullscreenchange", this.exitHandler), document.removeEventListener("MSFullscreenChange", this.exitHandler), window.removeEventListener("message", this.addMessageListen), this.root.removeEventListener("mouseover", this.mouseleaveFunc), this.buttonShowOrHiddenFlag && clearTimeout(this.buttonShowOrHiddenFlag), this.recordListTimer && clearTimeout(this.recordListTimer), this.timestampTimer && clearInterval(this.timestampTimer), this.requestRecordListTimer && clearTimeout(this.requestRecordListTimer), this.timeLine && this.timeLine.stop(), (this.playing = !1), t)) {
                    for (this.resizeObserver.unobserve(this.root); this.root.firstChild; ) this.root.removeChild(this.root.firstChild);
                    this.timeLine = null;
                  }
                },
              },
              {
                key: "requestRecordList",
                value: function () {
                  if (0 === this.record.length)
                    this.emitMessage({
                      type: "getRecordList",
                      params: {
                        beginTime: this.status.beginTime,
                        endTime: this.status.endTime,
                        recordType: this.status.recordType,
                      },
                    });
                  else {
                    var t = e()(this.status.beginTime).startOf("day").unix();
                    this.emitMessage({
                      type: "getRecordList",
                      params: {
                        beginTime: e()
                          .unix(this.record[this.record.length - 1].endTime + t)
                          .format("YYYY-MM-DD HH:mm:ss"),
                        endTime: this.status.endTime,
                        recordType: this.status.recordType,
                      },
                    });
                  }
                },
              },
              {
                key: "doRequestRecordList",
                value: function (t) {
                  var n = this,
                    i = e()(this.status.beginTime).startOf("day").unix();
                  (this.record = this.record.concat(
                    t.map(function (t) {
                      return { beginTime: e()(t.beginTime).unix() - i, endTime: e()(t.endTime).unix() - i };
                    })
                  )),
                    (("localRecord" === this.status.recordType && t.length >= 64) || t.length >= 99) &&
                      (this.requestRecordListTimer = setTimeout(function () {
                        n.requestRecordList();
                      }, 3e3)),
                    this.timeLine && this.timeLine.changeProp("record", this.record);
                },
              },
              {
                key: "changeRootWidthAndHeight",
                value: function (t, e) {
                  var n = this;
                  (this.root.style.width = "".concat(t, "px")),
                    (this.root.style.height = "".concat(e, "px")),
                    setTimeout(function () {
                      n.timeLine && n.timeLine.changeProp("width", t);
                    }, 100);
                },
              },
              {
                key: "createTimer",
                value: function () {
                  var t = document.createElement("div");
                  t.setAttribute("class", "reckon-time reckon-time_".concat(this.iframeId)), (t.innerHTML = "00:00:00");
                  var e = 0,
                    n = 0,
                    i = 0,
                    a = 0;

                  function r(t) {
                    return t < 10 ? "0".concat(t) : "".concat(t);
                  }

                  setInterval(function () {
                    var o;
                    a++, (t.innerHTML = (o = a) < 10 ? "00:00:0" + o : o < 59 ? "00:00:" + o : o < 3600 ? ((n = Math.trunc((o / 60).toFixed(2))), (i = o % 60), "00:" + r(n) + ":" + r(i)) : o >= 3600 ? ((e = Math.trunc(o / 3600)), (n = Math.trunc((o - 3600 * e) / 60)), (i = o % 60), r(e) + ":" + r(n) + ":" + r(i)) : void 0);
                  }, 1e3),
                    this.root.appendChild(t);
                },
              },
              {
                key: "destoryTimer",
                value: function () {
                  var t = document.getElementsByClassName("reckon-time_".concat(this.iframeId));
                  t[0] && this.root.removeChild(t[0]);
                },
              },
            ]),
            i && v(n.prototype, i),
            Object.defineProperty(n, "prototype", { writable: !1 }),
            t
          );
        })();
        window && (window.imouPlayer = g);
        const b = g;
      })(),
      i
    );
  })()
);
