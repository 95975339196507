<template>
  <div id="player"></div>
</template>

<script>
import imouPlayer from "./imou-player.js";
export default {
  name: "IMouVideo",
  props: {
    deviceId: {
      type: String,
      default: "",
    },
    channelId: {
      type: String,
      default: "0",
    },
    pwd: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 915,
    },
    height: {
      type: Number,
      default: 610,
    }
  },
  data() {
    return {
      kitToken: "",
    };
  },
  created() {
    this.getKitToken();
  },
  methods: {
    getKitToken() {
      let params = {
        deviceId: this.deviceId,
        channelId: this.channelId,
      };
      this.topsAjax.post(`${this.baseUrls.device}/getKitToken`, params).then((response) => {
        this.kitToken = response;
        if (response !== undefined) {
          this.initPlayer();
        }
      });
    },
    initPlayer() {
      // 添加DOM容器
      const player = new imouPlayer({
        id: "player",
        width: this.width,
        height: this.height,
        // 设备序列号
        deviceId: this.deviceId,
        token: this.kitToken,
        channelId: this.channelId,
        // 1 直播  2 录播
        type: 1,
        // 直播 0 高清  1 标清  默认
        streamId: 0,
        // 录播 云录像 1 本地录像 localRecord 默认 云录像
        recordType: "cloud",
        // 如果设备设置了自定义音视频加密秘钥，则输入此秘钥；
        // 如果设备只设置了设备密码，则输入设备密码；其他情况默认设备序列号。
        code: this.pwd,
      });
    },
  },
};
</script>

<style scoped></style>
